.user-import-intro {
  --height: 280px;
}

.import-users-card {
  --height: 568px;
  transition: width 0.5s, height 0.5s, transform 0.5s;
  --border-radius: 10px;
  --background: #ffffff;
}

.import-users-card-tenant {
  --width: 936px;
  transition: width 0.5s, height 0.5s, transform 0.5s;
}

.import-users-card-location {
  --width: 736px;
  transition: width 0.5s, height 0.5s, transform 0.5s;
}

.user-intro-holder {
  margin-left: 30px;
  margin-right: 30px;
  width: 500px;
}

.user-intro-message {
  height: 200px;
  font-size: 16px;
  letter-spacing: -0.5px;
  line-height: 24px;
}

.user-intro-message h2 {
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 32px;
  margin: 20px;
}

.intro-import-users-button {
  margin-top: 10px;
}

.user-intro-buttons {
  box-shadow: inset 0 1px 0 0 #f3f3f3;
}

.import-later {
  cursor: pointer;
  margin-top: 15px;
}

/* .location-button-label {
    width: 102px;
} */
