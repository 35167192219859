.payment-details {
    --width: 47rem;
    --height: 32rem;
    --border-radius: 8px;
    --background: white;
    transition: width .5s, height .5s, transform .5s max-height .5s;
}

.payment-details .modal-wrapper {
    width: 744px;
    height: calc(100vh - 100px);
    max-height: 500px;
    transition: width .5s, height .5s, transform .5s max-height .5s;
}

.payment-details .buttonsHolder {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.payment-details ion-button.save-setting {
    margin: 0;
}

.payment-details ion-button.onboarding-next {
    margin-left: auto;
}

.refunds {
    --width: 47rem;
    --height: 32rem;
    --border-radius: 8px;
    --background: white;
    transition: width .5s, height .5s, transform .5s max-height .5s;
}

.refunds .modal-wrapper {
    width: 744px;
    height: calc(100vh - 100px);
    max-height: 520px;
    transition: width .5s, height .5s, transform .5s max-height .5s;
}

.pos-device-settings {
    --width: 47rem;
    --height: 50rem;
    --border-radius: 8px;
    --background: white;
    transition: width .5s, height .5s, transform .5s;
}

.pos-device-settings .modal-wrapper {
    width: 744px;
    height: calc(100vh - 100px);
    max-height: 790px;
    transition: width .5s, height .5s, transform .5s max-height .5s;
}

.modal-content {
    --background: transparent;
}