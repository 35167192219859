.taxes-page-grid {
    margin-left: calc(50% - 372px);
}

.taxes-card-container {
    margin-top: 30px;
}

.action-items {
    --padding-start: 0px;
    --ion-background-color: #ffffff;
    --ion-item-background: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px;
    display: flex;
    cursor: pointer;
}

.advanced-taxes-icon {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.advanced-taxes-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 40px;
}

.advanced-taxes-subtext {
    font-size: 14px;
    color: #989898;
    margin-top: 20px;
}

.advanced-taxes-header {
    margin-left: 25px;
    margin-bottom: 30px;
}

.advanced-taxes-body {
    overflow: visible !important;
    height: 100%;
}

.advanced-grid {
    height: 400px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    overflow: visible !important;
    position: relative;
    font-size: 14px;
}

.taxes-modal {
    --width: 75rem;
    --height: initial;
    --background: white;
}

.taxes-modal .modal-wrapper {
    background: #ffffff;
    border-radius: 8px;
    position: relative;
    overflow: visible !important;
    width: 1200px;
    height: 617px;
}

.tax-rate-input:after {
    content: "%";
    font-size: 20px;
    color: #000000;
    margin-left: 5px;
    margin-right: 40px;
}

.advanced-tax-item {
    --padding-start: 0px;
    --ion-background-color: #ffffff;
    --ion-item-background: #ffffff;
    --border-color: #eee;
    letter-spacing: -0.5px;
    cursor: pointer;
}

.selected-tax {
    --padding-start: 0px;
    --ion-background-color: var(--ion-color-primary);
    --ion-item-background: var(--ion-color-primary);
    --border-color: #eee;
    letter-spacing: -0.5px;
    color: #ffffff;
    cursor: pointer;
}

.advanced-grid-jurisdictions-text {
    margin: 20px;
    font-size: 14px;
}

.advanced-grid-jurisdictions {
    width: 250px;
    vertical-align: top;
}

.placeholder-option {
    color: #eee;
}

.tax-input-percent:after {
    content: "%";
    font-size: 14px;
    color: #000000;
    margin-left: 5px;
    margin-right: 30px;
}

.tax-input-dollar:before {
    content: "$";
    font-size: 14px;
    color: #000000;
    margin-left: 5px;
}

.advanced-tax-header-row {
    border-bottom: 5px solid #eee !important;
    border-top: 0px;
    width: 110px;
    height: 52px !important;
    font-size: 12px;
    text-align: left;
    padding-left: 18px;
}

.advanced-taxes-footer {
    height: 100%;
    margin: 20px;
}

.advanced-tax-row-data {
    height: 56px;
}

.advanced-tax-row-data select {
    padding-left: 18px;
    height: 100%;
    background: transparent;
}

.advanced-tax-row-data input {
    padding-left: 18px;
    height: 100%;
}

.advanced-tax-row-data ion-input {
    min-height: initial !important;
    display: flex;
    flex-direction: row;
    --padding-start: 10px;
}

.advanced-tax-row-data ion-toggle {
    padding-left: 18px;
}

.unselected {
    color: #c5c6c6;
}

.unselected option {
    color: #000000;
}

.advanced-taxes-modal-close {
    width: 30px;
    height: 30px;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}

.invalid {
    border: 1px solid #ff0000 !important;
}

.advanced-taxes-cancel-button {
    --background: #EFEFEF;
    color: #000000;
}

.taxes-validation-message{
    margin-top: 16px;
}