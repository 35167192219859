.amazon-account-auth-modal .amazon-account-auth-modal-dialog {
    max-width: 550px;
}

.amazon-account-auth-modal .amazon-account-auth-modal-dialog .amazon-account-auth-modal-header {
    padding: 1rem;
    border-bottom: 1px solid #F3F3F3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.amazon-account-auth-modal .amazon-account-auth-modal-dialog .amazon-account-auth-modal-body {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.amazon-account-auth-modal .amazon-account-auth-modal-dialog .amazon-account-auth-modal-body .authorize-button {
    background-color: #616ae8;
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 20px;
    box-shadow: none;
    border: none;
    padding: 0.5rem 1rem;
    width: 120px;
}

.amazon-account-auth-modal .amazon-account-auth-modal-dialog .amazon-account-auth-modal-body .authorize-button:hover {
    opacity: 0.9;
}