.ecommerce-account-locations-table {
    justify-content: center;
}

.ecommerce-account-locations-table .table-header {
    display: flex;
    flex-direction: row;
    font-size: 15px;
}

.ecommerce-account-locations-table .table-header .inventory,
.ecommerce-account-locations-table .table-header .sale {
    text-align: center;
}

.ecommerce-account-locations-table .location-items {
    display: flex;
    flex-direction: column;
}

.ecommerce-account-locations-table .location-items .active {
    color: white;
    background-color: #616AE8;
}

.ecommerce-account-locations-table .location-items .location-item {
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    border: 1px solid #00000020;
}

.ecommerce-account-locations-table .location-items .location-item .location-check-box-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ecommerce-account-locations-table .location-items .location-item .location-check-box-holder .location-check-box {
    accent-color: #616AE8;
    box-shadow: none;
}

.ecommerce-account-locations-table .location-items .location-item .location-check-box-holder .location-check-box:checked {
    background-color: #616AE8;
}

.ecommerce-account-locations-table .location-items .location-item .location-check-box-holder .location-check-box:disabled {
    border: 1px solid #00000020;
}

.ecommerce-account-locations-table .location-items .location-item .location-name {
    text-align: start;
}

.ecommerce-account-locations-table .location-content .location-information {
    color: black;
}