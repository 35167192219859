.disabled {
   opacity: 1 !important;
}

ion-row.device-grid-header {
    color: #000000;
    height: 40px;
    font-size: 12px;
    font-family: 'Inter-bold';
    letter-spacing: -0.5px;
    line-height: 16px;
    align-items: center;
    background: #ffffff;

    border-bottom-color: #F4F4F6;
    border-bottom-width: 4px;
    border-bottom-style: solid;

    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.devices-grid {
    --ion-grid-columns: 14;
}

.delete-devices-modal {
    --border-radius: 8px;
    --height: 300px;
    --width: 500px;
    --ion-background-color: #ffffff;
}

.delete-devices-modal ion-toolbar {
    --background: #ffffff;
    --ion-toolbar-background: #ffffff;
}

.delete-devices-modal ion-list {
    --ion-item-background: #ffffff;
    position: absolute;
    bottom: 16px;
    right: 25%;
    left: 50%;
    margin-left: -25%;
}

.delete-devices-modal ion-title {
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 32px;
}

.delete-devices-modal ion-content {
    --padding-start: 40px;
    --padding-end: 40px;
}

.close-button {
    height: 24px;
    width: 24px;
    fill: #000000;
    cursor: pointer;
}

.nevermind-button {
    --background: #ffffff;
    --color: #808080;
    height: 40px;
    width: 200px;
}

.delete-all-devices-button {
    --background: #FA5A5A;
    height: 40px;
    width: 200px;
}

.location-devices-grid {
    --ion-grid-width-xs: 100%;
    --ion-grid-width-sm: 100%;
    --ion-grid-width-md: 100%;
    --ion-grid-width-lg: 100%;
    --ion-grid-width-xl: 100%;
    height: calc(100vh);
    min-height: 100%;
    overflow-y: auto;
}

.all-devices-grid {
    --ion-grid-width-xs: 80%;
    --ion-grid-width-sm: 80%;
    --ion-grid-width-md: 80%;
    --ion-grid-width-lg: 80%;
    --ion-grid-width-xl: 80%;
}

ion-row.device-grid-row {
    height: 56px;
    background: #ffffff;
    color: #7F7F7F;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    align-items: center;
    margin-bottom: 1px;
}

.ion-row-cursor {
    cursor: pointer;
    user-select: text;
}

.device-name-col {
    color: #000000;
}

ion-checkbox {
    margin-left: 20px;
    display: flex;
    --background: #EDEDED;
    --border-color: #EDEDED;
    --background-checked: #616AE8;
    --border-color-checked: #616AE8;
    height: 16px;
    width: 16px;
}

.device-actions-button {
    --background: #EDEDED;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-size: 12px;
    --box-shadow: none;
    margin-left: auto;
    margin-right: 16px;
}

.device-actions-icon {
    font-size: 13px;
    fill: #000000;
}

.device-actions-popover {
    --backdrop-opacity: 0
}

.device-actions-popover-buttons {
    color: #000000;
    text-transform: none;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.ion-button-inner-contents {
    text-align: left;
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.device-menu-button-icon {
    height: 16px;
    width: 16px;
}

.device-delete-button {
    color: #FA5A5A;
}

.divider-button {
    height: 1px;
    --background: #EDEDED;
}

.device-status-item {
    --background: #C0C0C0;
    --min-height: 24px;
    --border-radius: 4px;
    font-size: 12px;
    width: fit-content;
    --padding-end: 8px;
    --padding-start: 8px;
    --inner-padding-end: 0px;
    text-align: center;
    --color: white;
    letter-spacing: -0.5px;
    line-height: 16px;
    text-transform: capitalize;
}

.device-status-item.active {
    --background: #00C19C;
}

.device-status-label {
    margin: 0px;
}

.devices-grid ion-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


