.role-popover {
	--background: #ffffff;
}

.role-popover ion-list {
	background-color: #ffffff;
}

.role-popover ion-item {
    --background: #ffffff;
    cursor: pointer;
}

.role-popover ion-item:hover {
    --background: #ffffff;
    cursor: pointer;
}

.role-popover .role-popover-checkbox {
    margin: 0 10px;
}

.role-popover .role-popover-title {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 20px;
    width: 100%;
}

.role-popover .role-popover-description {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
}

