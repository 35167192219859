.activation-page {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #592A60 0%, #480851 100%, #7851FF 100%);
}

.activation-content {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    width: calc(100% /3);
}

.activation-circle-border {
    box-sizing: border-box;
    width: max-content;
    display: block;
    margin: auto;
    border-radius: 50%;
}

.activation-logo-transparent-border {
    border: 10px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
}

.activation-logo-white-border {
    border: 10px solid #ffffff;
    background-color: #FFFFFF;
}

.activation-logo-image {
    height: 105px;
    width: 105px;
    display: block;
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
}

.activation-centered-text {
    letter-spacing: -0.5px;
    text-align: center;
}

.activation-header {
    color: #FFFFFF;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    margin-bottom: 25px;
    margin-top: 25px;
}

.activation-text {
    color: #FFFFFF;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 25px;
}

.activation-subtext {
    color: #808080;
    font-size: 16px;
    line-height: 24px;
}