.rics-modal-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1000;
  top: 0;
}

.rics-modal {
  background-color: white;
  display: flex;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  position: relative;
}