.amazon-page .add-account-header-button {
  background: #616ae8;
  --color: #ffffff;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.amazon-content {
  --background: #f4f4f6;
  --padding-bottom: 200px;
  display: flex;
  flex-direction: column;
}

.amazon-content .integration-card-logo-holder {
  height: 130px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

/* Amazon Account Management */
#amazon-account-management .ecommerce-account-details {
  height: initial;
}

#amazon-account-management .integration-card-logo-holder {
  height: 130px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}