.ecommerce-accounts-heading {
  font-size: 20px;
  margin-bottom: 20px;
}

.ecommerce-accounts-grid {
  --ion-grid-columns: 12;
  --ion-grid-width-xs: 80%;
  --ion-grid-width-sm: 80%;
  --ion-grid-width-md: 80%;
  --ion-grid-width-lg: 80%;
  --ion-grid-width-xl: 80%;
}

.ecommerce-accounts-grid ion-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-row.ecommerce-accounts-grid-header {
  color: #000000;
  height: 40px;
  font-size: 12px;
  font-family: "Inter-bold";
  letter-spacing: -0.5px;
  line-height: 16px;
  align-items: center;
  background: #ffffff;

  border-bottom-color: #f4f4f6;
  border-bottom-width: 4px;
  border-bottom-style: solid;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

ion-row.ecommerce-accounts-grid-row {
  height: 56px;
  background: #ffffff;
  color: #7f7f7f;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 1px;
  cursor: pointer;
  user-select: text;
}

.ecommerce-account-label-col {
  color: #000000;
}

.ecommerce-account-status-item {
  --background: #fa5a5a;
  --min-height: 24px;
  --border-radius: 4px;
  font-size: 12px;
  width: fit-content;
  --padding-end: 8px;
  --padding-start: 8px;
  --inner-padding-end: 0px;
  text-align: center;
  --color: white;
  letter-spacing: -0.5px;
  line-height: 16px;
  text-transform: capitalize;
}

.ecommerce-account-status-item.active {
  --background: #00c19c;
}

.ecommerce-account-status-label {
  margin: 0px;
}

.ecommerce-account-actions-button {
  --background: #ededed;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  font-size: 12px;
  --box-shadow: none;
  margin-left: auto;
  margin-right: 16px;
}

.ecommerce-account-actions-icon {
  font-size: 13px;
  fill: #000000;
}

.ecommerce-account-actions-popover {
  background: #ffffff;
}

.ecommerce-account-popover-button {
  color: #000000;
  text-transform: none;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.ecommerce-account-deactivate-button {
  color: #fa5a5a;
}

.ecommerce-account-popover-button-inner-contents {
  text-align: left;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.ecommerce-account-popover-button-icon {
  height: 16px;
  width: 16px;
}
