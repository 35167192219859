#existingUsersModal {
	height: 80%;
	min-width: 800px;
}

#existingUsersModal .topRow {
	padding-left: 25px;
	text-align: center;
}

#existingUsersModal .middleRow {
	max-height: 500px;
	margin-top: 30px;
	overflow-y: scroll;
}

#existingUsersModal #employeeTable {
	margin-top: 30px;
}

#existingUsersModal #employeeTable input {
	padding: 10px 10px;
	border-radius: 3px;
	border: 1px solid #efefef;
}

#existingUsersModal #employeeTable input::placeholder {
	color: #b7b8b8;
}

#existingUsersModal #employeeTable .checkbox input:checked~.checkmark {
	background: white;
	border: 5px solid #616AE8;
}

#existingUsersModal #employeeTable .emailHolder.invalid {
	border: 2px solid #FF0000;
}

#existingUsersModal .heading {
	font-size: 24px;
	margin-top: 40px;
}

#existingUsersModal .subHeading {
	font-size: 14px;
	color: #989898;
	margin-top: 20px;
}

#existingUsersModal .bottomRow {
	position: relative;
	border-top: 1px solid #efefef;
	padding-top: 20px;
	text-align: justify;
	height: 88px;
}

#existingUsersModal .bottomRow>* {
	position: absolute;
	padding: 0 15px;
	top: 50%;
	height: 40px;
	transform: translateY(-50%);
}

#existingUsersModal .bottomRow .importButton {
	background: #616AE8;
	right: 20px;
}

#existingUsersModal .bottomRow .importButton:disabled {
	background: rgba(97, 106, 232, 0.5);
	color: rgba(255, 255, 255, 0.5);
	cursor: unset;
}

#existingUsersModal .bottomRow .cancelButton {
	color: black;
	background: #efefef;
	left: 20px;
}

#existingUsersModal .exitButton {
	position: absolute;
	right: 20px;
	top: -21px;
	height: 20px;
	width: 20px;
	cursor: pointer;
}

ion-content.add-existing-users-grid {
	--ion-background-color: #ffffff;
	border-top: solid 5px #EDEDED;
	border-bottom: solid 1px #EDEDED;
}

.user-lineItem {
	--background: #ffffff;
	--border-color: #EDEDED;
	font-size: 14px;
	letter-spacing: -0.5px;
	line-height: 20px;
	color: #7F7F7F;
}

.add-existing-user-header-table {
	margin-left: 5px;
}

.add-existing-user-header {
	color: #000000;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: -0.5px;
	line-height: 16px;
	text-align: left;
	padding: 10px;
}

.add-existing-users-grid {
	height: 284px;
}

.add-existing-users-card .modal-wrapper {
	height: 524px;
	width: 700px;
}

.no-click {
	pointer-events: none !important;
}

.chip-no-click {
	pointer-events: none;
	--background: #616AE8;
	height: 24px;
	width: 32px;
	margin-right: 15px;
}

.chip-no-click ion-label {
	color: #ffffff;
}

.failure-popover {
	min-width: 520px;
}

.failure-popover div {
	margin: 15px;
}

ion-list.failures-list {
	background: #ffffff;
}

.existing-user-id {
	margin-left: 16px;
	margin-right: 5px;
	width: 130px;
}

.existing-user-first-name {
	margin-left: 16px;
	margin-right: 5px;
	width: 130px;
}

.existing-user-last-name {
	width: 130px;
	margin-left: 5px;
	margin-right: 5px;
}

.existing-user-email {
	width: 265px;
	margin-left: 5px;
	margin-right: 5px;
}

.import-users-button {
	margin: 24px;
}

.selected-employees {
	font-size: 12px;
	letter-spacing: -0.5px;
	line-height: 16px;
	margin-top: 30px;
	margin-left: 15px;
}

.user-close-button {
	height: 30px;
	width: 30px;
	margin-right: 28.5px;
	margin-top: 28.5px;
	fill: #000000;
	cursor: pointer;
}

.checkboxHolder {
	display: inline;
}

.checkboxHolder input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
}

.checkbox {
	display: block;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkmarkDisabled {
	background-color: white;
}

.checkmark {
	float: left;
	height: 17px;
	width: 17px;
	margin-right: 15px;
	margin-left: 15px;
	border-radius: 2px;
	background: #EDEDED;
	cursor: pointer;
}

.checkmarkEnabled {
	background-color: #eee;
}

.checkbox:hover input~.checkmarkDisabled {
	background-color: white;
}

.checkbox:hover input~.checkmarkEnabled {
	background-color: #ccc;
}

.checkbox input:checked~.checkmark {
	background: white;
	border: 5px solid #616AE8;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox input:checked~.checkmark {
	background: white;
	border: 5px solid #616AE8;
}
