.tenant-header {
  margin-bottom: 24px;
}

.tenant-header-toolbar {
  --background: #ffffff;
  /* todo: check why the other content was 40px total when I think they should both be 24px */
  --padding-start: 10%;
  --padding-end: 10%;
  --padding-top: 16px;
  --padding-bottom: 16px;
  max-height: 64px;
  display: flex;
  align-content: center;
  align-items: center;
}

.tenant-header-icon {
  opacity: 1;
  height: 50%;
  width: 50%;
  transform: translate(50%, 50%);
  filter: invert(98%) sepia(2%) saturate(300%) hue-rotate(188deg)
    brightness(121%) contrast(100%);
}

.header-circle {
  background: #616ae8;
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 100%;
}

.items-count {
  padding-left: 8px;
  color: #c0c0c0;
}

.tenant-header-title {
  font-size: 24px;
  letter-spacing: -0.5px;
  line-height: 28px;
  padding-left: 16px;
}
