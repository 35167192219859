.passwordPage{
	height: 100%;
	overflow-y: auto;
	background: #592A60;
}

#passwordFormHolder {
	width: 95%;
	overflow: auto;
	letter-spacing: -0.5px;
	margin: 60px auto;
}

#passwordFormHolder .topSection {
	text-align: center;
}

#passwordFormHolder .topSection .logoHolder {
	margin-bottom: 60px;
}

#passwordFormHolder .topSection .logoHolder svg {
	width: 240px;
	height: 100%;
}

#passwordFormHolder .topSection .heading {
	color: white;
	font-size: 36px;
	line-height: 48px;
	margin-bottom: 20px;
}

#passwordFormHolder .topSection .subHeading {
	color: white;
	font-size: 16px;
	margin-bottom: 25px;
	line-height: 24px;
}

#passwordFormHolder .topSection .subHeading.review {
	font-size: 20px;
	line-height: 28px;
}

#passwordFormHolder .passwordFormSection {
	width: 25%;
	margin: auto;
	min-width: 550px;
}

#passwordFormHolder .passwordFormSection .passwordFormContent {
	margin: 25px 0;
}

#passwordFormHolder .passwordFormSection .passwordFormContent .passwordFormContentRow .formFieldHolder:first-child {
	margin-right: 12px;
}

#passwordFormHolder .passwordFormSection .passwordFormContent .passwordFormContentRow .formFieldHolder:last-child {
	margin-left: 12px;
}

#passwordFormHolder .passwordFormSection .formFieldHolder {
	min-width: 200px;
	margin-bottom: 25px;
}

#passwordFormHolder .passwordFormSection .formFieldHolder .formFieldHeader {
	font-size: 14px;
	height: 20px;
	color: #FFFFFF;
	text-align: left;
	margin-bottom: 8px;
}

#passwordFormHolder .passwordFormSection .formFieldHolder .formFieldElement {
	outline: 0;
	border-width: 0 0 1px;
	border-color: white;
	background: white;
	padding: 20px;
	font-size: 18px;
	border-radius: 8px;
	text-align: left;
	width: 100%;
	color: #161A1B;
}

#passwordFormHolder .passwordFormSection .formFieldHolder .formFieldElement:disabled {
	background: #D9D9D9;
	color: #989898;
	user-select: none;
}

#passwordFormHolder .passwordFormSection.review .formFieldHolder .formFieldHeader {
	opacity: 0.25;
}

#passwordFormHolder .passwordFormSection.review .formFieldHolder .formFieldElement {
	height: 24px;
	color: #FFFFFF;
	background-color: unset;
	border-radius: unset;
	padding: unset;
}

#passwordFormHolder .passwordFormSection .formFooterMessage {
	text-align: center;
	width: 100%;
	color: #FFFFFF;
	opacity: 0.5;
	font-size: 14px;
}

#passwordFormHolder .passwordFormSection .buttonsHolder {
	width: 100%;
}

#passwordFormHolder .passwordFormSection .buttonsHolder .buttonDefault {
	text-align: center;
	width: 100%;
	border-radius: 4px;
	font-size: 16px;
	line-height: 24px;
	height: 54px;
	margin: 25px 0;
}

#passwordFormHolder .passwordFormSection .buttonsHolder .buttonDefault.submitButton {
	background-color: #616AE8;
	color: #FFFFFF;
	opacity: 1;
}

#passwordFormHolder .passwordFormSection .buttonsHolder .buttonDefault.submitButton.disabled {
	background: rgba(255, 255, 255, 0.25);
	color: rgba(255, 255, 255, 0.5);
}

#passwordFormHolder .passwordFormSection .buttonsHolder .buttonDefault.editButton {
	background-color: #FFFFFF;
	color: #592A60;
}

#passwordFormHolder .passwordFormSection .buttonsHolder.review .buttonDefault:first-child {
	width: 50%;
	margin-right: 12px;
}

#passwordFormHolder .passwordFormSection .buttonsHolder.review .buttonDefault:last-child {
	width: 50%;
	margin-left: 12px;
}


@media screen and (max-width: 1024px) {
	#passwordFormHolder .passwordFormSection {
		width: 50%;
		min-width: 300px;
	}

	#passwordFormHolder .passwordFormSection .layout-row {
		display: flex;
		flex-direction: column;
		-webkit-flex-direction: column;
	}

	#passwordFormHolder .passwordFormSection .passwordFormContent .passwordFormContentRow .formFieldHolder:first-child {
		margin-right: unset;
	}

	#passwordFormHolder .passwordFormSection .passwordFormContent .passwordFormContentRow .formFieldHolder:last-child {
		margin-left: unset;
	}

	#passwordFormHolder .passwordFormSection .buttonsHolder.review .buttonDefault:first-child {
		width: 100%;
		margin-right: unset;
	}

	#passwordFormHolder .passwordFormSection .buttonsHolder.review .buttonDefault:last-child {
		width: 100%;
		margin-left: unset;
	}
}
