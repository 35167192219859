.rics-toolbar{
  --min-height: 0px;
  --background: black;
  --color: #ffffff;
}

.rics-logo{
  width: 94px;
  height: 20px;
}

.account-image {
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

.my-account-icons {
  fill: white;
  opacity: 1.0;
  margin: 4px 0px 4px 4px;
}

.app-buttons-icon{
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 13px;
  display: block;
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(200deg) brightness(106%) contrast(101%);
}

.circle {
  background: #404040;
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 100%;
  margin-left: 16px;
}

.rics-toolbar-button{
  text-transform: capitalize;
  --color: white;
  height: 48px;
  margin: 0px;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.rics-setup-wizard{
  --background: #616AE8;
  --color: white;
  text-transform: capitalize;
  margin-left: 16px;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  height: 24px;
  --padding-start: 4px;
  --padding-end: 6px;
}

.rics-setup-label{
  color: white;
}

.rics-toolbar-img{
  width: 16px;
  height: 16px;
  /* fill: white; doesn't work, but filter does https://codepen.io/sosuke/pen/Pjoqqp*/
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(105%) contrast(101%);
  margin: 4px 8px 4px 0;
}

ion-toolbar ion-button.selected {
  background-color: rgba(255, 255, 255, 0.25);
}

#notProductionIndicator {
  position: fixed;
  height: 50%;
	width: 94px;
  color: #FA5A5A;
  font-weight: bolder;
  background: #FFFFFF;
  font-size: 18px;
	text-align: center;
}

#logoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.my-account-popover {
  --background: #252525;
}

.vertical-line {
  width: 1px;
  background:rgba(255, 255,255, 0.25);
}

.button-left-icon {
  padding-right: 12px;
}

.button-right-icon {
  padding-left: 16px;
}
