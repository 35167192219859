#location-details-branding {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#location-details-branding .tabbedSection {
    position: relative;
/* accounts for the header bars' heights above the content */
    height: calc(100% - 104px);
}

#location-details-branding .tabbedSection .tabContent {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

#location-details-branding .tabbedSection .tabContent .tabInnerContent {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    display: inline-block;
    min-height: 56px;
    margin-bottom: 40px;
}

#location-details-branding .tabbedSection .tabContent .tabInnerContent .contentSection {
    border-radius: 8px;
    background-color: white;
    padding: 25px;
    margin-bottom: 40px;
}