.accountSetupCompletePage {
	height: 100%;
	overflow-y: auto;
	background: #592A60;
}
#accountSetupCompleteHolder {
	width: 95%;
	height: 80%;
	overflow: auto;
	letter-spacing: -0.5px;
	margin: 60px auto;
	position: relative;
}
#accountSetupCompleteHolder .topSection {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
#accountSetupCompleteHolder .topSection .logoHolder {
	margin-bottom: 60px;
}
#accountSetupCompleteHolder .topSection .logoHolder svg {
	width: 240px;
	height: 100%;
}
#accountSetupCompleteHolder .topSection .heading {
	color: white;
	font-size: 36px;
	line-height: 48px;
	margin-bottom: 20px;
}
#accountSetupCompleteHolder .topSection .subHeading {
	color: white;
	font-size: 16px;
	margin-bottom: 25px;
	line-height: 24px;
}
#accountSetupCompleteHolder .topSection .subHeading.review {
	font-size: 20px;
	line-height: 28px;
}
#accountSetupCompleteHolder .topSection .subHeadingHolder svg {
	cursor: pointer;
}
