#location-details-details {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

#location-details-details .tabbedSection {
    position: relative;
/* accounts for the header bars' heights above the content */
    height: calc(100% - 104px);
}

#location-details-details .tabbedSection.setup {
    height: calc(100% - 238px);
}

#location-details-details .tabbedSection .tabContent {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

#location-details-details .tabbedSection .tabContent .tabInnerContent {
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    display: inline-block;
    min-height: 56px;
    margin-bottom: 40px;
}

#location-details-details .tabbedSection .tabContent .tabInnerContent .contentSection {
    border-radius: 8px;
    background-color: white;
    padding: 25px;
    margin-bottom: 40px;
}

.tabbedSection .tabContent #Details #locationMap {
    height: 207px;
    background: #616AE8;
    border-radius: 8px;
}

.tabbedSection .tabContent #Details .headingHolder {
    margin-top: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;
}

.tabbedSection .tabContent #Details .headingHolder .heading {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
}

.tabbedSection .tabContent #Details .headingHolder .headingNote {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    background: #989898;
    color: white;
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 5px;
}

.tabbedSection .tabContent #Details .row {
    margin-top: 35px;
    color: #989898;
}

.tabbedSection .tabContent #Details .row .leftContent {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    width: 20%;
}

.tabbedSection .tabContent #Details .row .leftContent .heading {
    display: inline-block;
}

.tabbedSection .tabContent #Details .row .rightContent {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    width: 80%;
}

.tabbedSection .tabContent #Details .row .rightContent .editFieldHolder {
    display: inline-block;
    margin-bottom: 20px;
    padding-right: 20px;
}

.tabbedSection .tabContent #Details .row .rightContent .editFieldHolder.hundred {
    width: 100%;
}

.tabbedSection .tabContent #Details .row .rightContent .editFieldHolder.fifty {
    width: 50%;
}

.tabbedSection .tabContent #Details .row .rightContent .editFieldHolder.twentyfive {
    width: 25%;
}

.tabbedSection .tabContent #Details .row .rightContent .editFieldHolder .heading {
    margin-bottom: 5px;
}

.tabbedSection .tabContent #Details .row .rightContent input {
    font-size: 14px;
    padding: 10px 15px;
    border: none;
    color: #000000;
    border-radius: 3px;
    width: 100%;
}

.tabbedSection .tabContent #Details .row .rightContent input:disabled {
    background: #d7d7d7;
}