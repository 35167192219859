.modal-card .modal-wrapper {
    background: #ffffff;
    border-radius: 8px;
    position: relative;
    overflow: visible !important;
}

.modal-card .ion-page {
    overflow: visible !important;
}

ion-content.onboarding-content {
    --ion-background-color: #f4f4f6;
    --ion-background-color-rgb: 255, 255, 255;
}

.click-label {
    cursor: pointer;
}

.workflow-grid {
    width: 659px;
    margin-top: 10px;
    margin-bottom: 50px;
}

.welcome-text {
    margin: 24px;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 24px;
}

.workflow-grid ion-col {
    margin-bottom: 5px;
}
