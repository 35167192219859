#usersGrid .gridPanel {
  display: inline-block;
  vertical-align: top;
  width: 264px;
  background: white;
  border-radius: 8px;
  margin: 10px;
}

  #usersGrid .gridPanel:hover {
    cursor: pointer;
  }

  #usersGrid .gridPanel .content {
    text-align: center;
    padding: 20px;
  }

    #usersGrid .gridPanel .content .userImageRow {
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
    }

    #usersGrid .gridPanel .content .userImageHolder {
      display: inline-block;
      position: relative;
      background: #efefef;
      border-radius: 100%;
      height: 72px;
      width: 72px;
      overflow: hidden;
    }

      #usersGrid .gridPanel .content .userImageHolder > svg {
        position: absolute;
        width: 50%;
        height: 50%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      #usersGrid .gridPanel .content .userImageHolder > img {
        width: 72px;
        height: 72px;
        object-fit: cover;
        object-position: 50% 50%;
      }

      #usersGrid .gridPanel .content .userImageHolder.placeHolder {
        background: #000000;
      }

        #usersGrid .gridPanel .content .userImageHolder.placeHolder > div {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF;
        }

    #usersGrid .gridPanel .content .heading {
      font-size: 16px;
      text-transform: capitalize;
      margin-bottom: 15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    #usersGrid .gridPanel .content .subHeading {
      font-size: 14px;
      text-transform: lowercase;
      color: #8c8e8f;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    #usersGrid .gridPanel .content .roleHolder {
      position: relative;
      display: inline-block;
      background: #edfafd;
      color: #616AE8;
      height: 24px;
      font-size: 12px;
      margin-top: 30px;
      border-radius: 4px;
      padding: 4px 9px 0px 5px;
    }

      #usersGrid .gridPanel .content .roleHolder > * {
        display: inline-block;
        vertical-align: middle;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 175px;
      }

      #usersGrid .gridPanel .content .roleHolder .roleIcon {
        height: 12px;
        margin-right: 5px;
        margin-top: -2px;
      }

        #usersGrid .gridPanel .content .roleHolder .roleIcon path {
          fill: #616AE8;
        }

.user-card-actions-button {
  --background: #EDEDED;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  font-size: 12px;
  --box-shadow: none;
  margin-left: auto;
  /* margin-right: 16px; */
}