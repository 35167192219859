.rics-tooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rics-tooltip-popup {
  display: none;
  top: 15px;
  z-index: 1000;
  max-width: 1000px;
  position: absolute;
  padding-top: 20px;
}

.rics-tooltip-popup-content {
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  padding: 10px;
  position: relative;
}

.rics-tooltip:hover .rics-tooltip-popup {
  display: block;
}

.rics-tooltip-popup:hover {
  display: block;
}

.rics-tooltip img {
  filter: invert(97%) sepia(0%) saturate(23%) hue-rotate(153deg) brightness(84%) contrast(97%);
}