ion-row.user-grid-header {
    color: #000000;
    height: 40px;
    font-size: 12px;
    font-family: 'Inter-bold';
    letter-spacing: -0.5px;
    line-height: 16px;
    align-items: center;
    background: #ffffff;
    border-bottom-color: #F4F4F6;
    border-bottom-width: 4px;
    border-bottom-style: solid;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.users-grid {
    --ion-grid-columns: 14;
}

.delete-users-modal .modal-wrapper {
    border-radius: 8px;
    height: 300px;
    width: 500px;
}

.delete-users-modal ion-toolbar {
    --background: #ffffff;
    --ion-toolbar-background: #ffffff;
}

.delete-users-modal ion-list {
    --ion-item-background: #ffffff;
    position: absolute;
    bottom: 16px;
    right: 25%;
    left: 50%;
    margin-left: -25%;
}

.delete-users-modal ion-title {
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 32px;
}

.delete-users-modal ion-content {
    --padding-start: 40px;
    --padding-end: 40px;
}

.close-button {
    height: 24px;
    width: 24px;
    fill: #000000;
    cursor: pointer;
}

.nevermind-button {
    --background: #ffffff;
    --color: #808080;
    height: 40px;
    width: 200px;
}

.delete-all-users-button {
    --background: #FA5A5A;
    height: 40px;
    width: 200px;
}

.location-users-grid {
    --ion-grid-width-xs: 100%;
    --ion-grid-width-sm: 100%;
    --ion-grid-width-md: 100%;
    --ion-grid-width-lg: 100%;
    --ion-grid-width-xl: 100%;
}

.all-users-grid {
    --ion-grid-width-xs: 80%;
    --ion-grid-width-sm: 80%;
    --ion-grid-width-md: 80%;
    --ion-grid-width-lg: 80%;
    --ion-grid-width-xl: 80%;
}

ion-row.user-grid-row {
    height: 56px;
    background: #ffffff;
    color: #7F7F7F;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    align-items: center;
    margin-bottom: 1px;
}

.user-name-col {
    color: #000000;
}

ion-checkbox {
    margin-left: 20px;
    display: flex;
    --background: #EDEDED;
    --border-color: #EDEDED;
    --background-checked: #616AE8;
    --border-color-checked: #616AE8;
    height: 16px;
    width: 16px;
}

.user-actions-button {
    --background: #EDEDED;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    font-size: 12px;
    --box-shadow: none;
    margin-left: auto;
    margin-right: 16px;
}

.user-actions-icon {
    font-size: 13px;
    fill: #000000;
}

.user-actions-popover {
    background: #ffffff;
}

.user-actions-popover-buttons {
    color: #000000;
    text-transform: none;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.ion-button-inner-contents {
    text-align: left;
    width: 100%;
    display: inline-flex;
    align-items: center;
}

.user-menu-button-icon {
    height: 16px;
    width: 16px;
}

.user-delete-button {
    color: #FA5A5A;
}

.divider-button {
    height: 1px;
    --background: #EDEDED;
}

.user-status-item {
    --min-height: 24px;
    --border-radius: 4px;
    font-size: 12px;
    width: fit-content;
    --padding-end: 8px;
    --padding-start: 8px;
    --inner-padding-end: 0px;
    text-align: center;
    letter-spacing: -0.5px;
    line-height: 16px;
    text-transform: capitalize;
    color: #616AE8;
    --background: #F4F4FD;
}

.user-status-label {
    margin: 0px;
}

.users-grid ion-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-cards-content {
    padding-left: 10%;
    padding-right: 10%;
}

.users-alert {
    border-radius: 4px;
    --ion-background-color: #616AE8;
    --ion-item-background: #616AE8;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    color: #ffffff;
    height: 45px;
    width: 65%;
    position: fixed;
    margin-top: 10px;
    margin-left: -20px
}

.users-alert-cover {
    margin-top: -10px;
    padding-top: 5px;
    height: 65px;
    width: 80%;
    background: rgb(244, 244, 246);
    z-index: 5;
    position: fixed;
}

.user-alert-padding {
    padding-top: 70px;
}

.icon-alert {
    height: 16px;
    width: 16px;
    margin-right: 12px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%) hue-rotate(267deg) brightness(103%) contrast(100%);
}

.icon-alert-close {
    cursor: pointer;
}

.user-grid-row-input {
    box-sizing: border-box;
    height: 36px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    color: #000000;
    padding: 5px;
    min-height: 36px !important;
    --padding-start: 5px;
}

.user-grid-row-input-invalid {
    border: 1px solid #ff0000 !important;
}

.delete-user-alert .alert-wrapper .alert-head {
    text-align: center;
}

.delete-user-alert .alert-wrapper .alert-head .alert-title {
    margin-bottom: 10px;
}
