.payment-card {
    --width: 50rem;
    --height: 29rem;
    --border-radius: 8px;
}

.payment-card .modal-wrapper {
    width: 744px;
    height: 467px;
    text-align: center;
}

.payment-card-merchant-id {
    --width: 37rem;
    --height: 20rem;
    --border-radius: 8px;
    --background: white;
    transition: width .5s, height .5s, transform .5s;
}

.payment-card-merchant-id .modal-wrapper {
    width: 600px;
    height: 350px;
    text-align: center;
    transition: width .5s, height .5s, transform .5s;
}

.payment-card-multi-account {
    --width: 37rem;
    --height: 16rem;
    --border-radius: 8px;
    --background: white;
    transition: width .5s, height .5s, transform .5s;
}

.payment-card-multi-account .modal-wrapper {
    width: 600px;
    height: 250px;
    text-align: center;
    transition: width .5s, height .5s, transform .5s;
}

.payment-heading {
    margin-top: 40px;
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 32px;
    text-align: center;
}

.payment-sub-heading {
    margin: 16px;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 24px;
    text-align: center;
}

.payment-card ion-col {
    display: flex;
    justify-content: center;
}

.modal-button {
    width: 320px;
    height: 211px;
    white-space: normal;
    --background: #ffffff;
    --color: #000000;
    --background-hover: #616AE8;
    --background-hover-opacity: 100%;
    --color-hover: #ffffff;
    margin: 12px;
}

.modal-button-heading {
    margin: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 24px;
}

.modal-button-text {
    word-wrap: normal !important;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.modal-button:hover ion-icon {
    fill: #ffffff;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(200deg) brightness(106%) contrast(101%);
}

.modal-button:hover object {
    fill: #ffffff;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(200deg) brightness(106%) contrast(101%);
}

.modal-button ion-icon {
    fill: #616AE8;
}

.rics-pay-column {
    height: 50px;
}

.rics-pay-icon {
    width: 100%;
    height: 100%;
}

.manual-icon {
    width: 35px;
    height: 47px;
}

.bank-icon {
    height: 47px;
    width: 46px;
    margin: 8px;
}

.merchant-id-header {
    height: 30%;
    padding-top: 10%;
    text-align: center;
}

.merchant-id-input ion-input {
    box-sizing: border-box;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    color: #000000;
    --padding-start: 5px;
    text-align: center;
}

.merchant-id-text {
    height: 25%;
    padding-top: 10%;
}

.multi-account-text {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 5%;
    text-align: center;
}

.multi-account-button ion-col {
    display: flex;
    justify-content: center;
}

.multi-account-button ion-button {
    width: 75px;
}