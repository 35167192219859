.location-details-toolbar {
	--background: #ffffff;
	/* todo: check why the other content was 40px total when I think they should both be 24px */
    --padding-start: 24px;
}

.location-details-header-icon {
	fill: #ffffff;
    opacity: 1.0;
    height: 50%;
    width: 50%;
    transform: translate(50%, 50%);
}

.header-circle {
    background: #616AE8;
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 100%;
}

.devices-count {
    padding-left: 8px;
    color: #C0C0C0;
}

.location-details-title {
    font-size: 20px;
    letter-spacing: -0.5px;
    line-height: 28px;
    padding-left: 16px;
}

.add-user-button {
    --background: #616AE8;
    --color: #ffffff;
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 6px;
    --padding-bottom: 6px;
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    margin-left: 8px;
    height: 32px;
}

.add-user-popover-icon {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(243deg) brightness(105%) contrast(101%);
    opacity: 0.25;
}

.add-user-popover-buttons {
    --color: black;
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: -0.5px;
    width: 98%;
}

.add-existing-user-icon{
    opacity: 0.25;
    color: #000000;
}
