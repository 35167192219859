.dropdown {
  position: relative;
  border: 1px solid rgba(0,0,0,0.2);
  width: 13rem;
  font-size: 14px;
}

.dropdown:hover {
  cursor: pointer;
}

.dropdown.disabled:hover {
 cursor: default;
}

.dropdown.disabled .dropdown-inner-content p {
  color: rgba(0,0,0,0.3);
}

.dropdown-inner-content {
  display: flex;
  padding: 2px 5px;
  justify-content: space-between;
  align-items: center;
}

.dropdown-inner-content img {
  filter: brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(749%) hue-rotate(150deg) brightness(91%) contrast(85%);
}

.dropdown-inner-content p {
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-placeholder {
  color: rgba(0,0,0,0.3);
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: inherit;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1000;
}

.dropdown-overlay {
  background-color:transparent;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:block;
  z-index: 50;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.dropdown-content a.disabled {
  color: rgba(0,0,0,0.3);
  background-color: transparent;
  cursor: default;
}