.advanced-taxes-content {
    height: 400px;
    width: 100%;
    overflow: visible !important;
    position: relative;
}

.advanced-taxes-content-rows-container {
    height: 345px;
    width: 100%;
    overflow: visible !important;
    position: relative;
}

.advanced-taxes-content-rows-container table {
    width: 100%;
}

.advanced-taxes-content-grid {
    overflow: visible !important;
    position: relative;
    margin-left: 1px;
}

.advanced-product-type-column {
    width: 280px;
    border: 1px solid #eee;
}

.advanced-product-type-column select {
    border: 0px;
    width: 100%;
}

.advanced-tax-rate-column {
    width: 140px;
    border: 1px solid #eee;
}

.advanced-tax-rate-column input {
    width: 100%;
    height: 100%;
    border: 0px;
}

.advanced-threshold-column {
    width: 187px;
    border: 1px solid #eee;
}

.advanced-threshold-column input {
    width: 100%;
    height: 100%;
    border: 0px;
}

ion-icon.advanced-tax-info {
    height: 16px;
    width: 16px;
}

ion-icon.advanced-tax-info:hover {
    cursor: pointer;
}

.advanced-tax-tooltip {
    position: absolute;
    display: inline;
}

.advanced-threshold-column .advanced-tax-tooltip {
    margin-left: 75px;
}

.advanced-tax-above-column .advanced-tax-tooltip {
    margin-left: 30px;
}

.advanced-tax-tooltip .advanced-tax-tooltiptext {
    visibility: hidden;
    width: 150px;
    height: 60px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    opacity: 0;
    transition: opacity 1s;
    font-size: 12px;
    letter-spacing: -0.5px;
    line-height: 16px;
    display: flex;
    position: absolute;
    z-index: 100;
    margin-left: -67px;
    margin-top: -85px;
    font-weight: normal;
}

.advanced-tax-tooltip .advanced-tax-tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.advanced-tax-tooltip:hover .advanced-tax-tooltiptext {
    opacity: 1;
    visibility: visible;
}

.advanced-tax-above-column {
    width: 233px;
    border: 1px solid #eee;
}

.advanced-delete-column {
    border: 1px solid #eee;
    width: calc(100% - 840);
}

.advanced-delete-column ion-icon {
    color: #c5c6c6;
    height: 22px;
    width: 22px;
    margin-left: calc(50% - 11px);
    cursor: pointer;
}