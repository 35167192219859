.disconnect-ricspay {
    cursor: pointer;
}

.modal-intro-message {
    height: 230px;
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 24px;
}

.modal-intro-message h2 {
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 32px;
    margin: 20px;
}

.modal-intro-holder {
    margin-left: 30px;
    margin-right: 30px;
}

.modal-intro-buttons {
    box-shadow: inset 0 1px 0 0 #F3F3F3
}

.link-existing-modal .modal-wrapper {
    height: 300px;
    background: #ffffff;
    border-radius: 8px;
    position: relative;
}

.existing-select {
    font-size: 16px;
    padding: 10px;
    width: 80%;
    margin: 30px 0;
    border: 1px solid #efefef;
    border-radius: 5px;
}

.icon-alert {
    height: 16px;
    width: 16px;
    margin-right: 12px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(99%) saturate(0%) hue-rotate(267deg) brightness(103%) contrast(100%);
}

.icon-alert-close {
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 6px;
}

.ricspay-active-account {
    width: 293px;
    border-radius: 8px;
}

.ricspay-active-icon {
    margin-right: 10px;
    background: white;
    padding: 3px;
    border-radius: 100%;
    height: 10px;
    width: 10px;
}

.merchant-id-holder {
    display: flex;
    flex-direction: column;
    width: 233px;
}

.merchant-id {
    box-sizing: border-box;
    height: 36px;
    width: 100%;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    color: #000000;
    padding: 5px;
    outline: none;
}

.merchant-id-verify-btn {
    width: 5rem;
}

.merchant-track-button {
    margin-top: 15px;
    margin-bottom: 15px;
}

.merchant-id-error,
.merchant-id-error:focus-visible {
    border: 1px solid #ff0000 !important;
}

.merchant-id-success {
    border: 1px solid #4BB543 !important;
}