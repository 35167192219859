.reputation-content-holder {
    display: flex;
    justify-content: center;
    max-height: 100%;
    overflow: hidden;
  }
  
.reputation-frame-holder {
    width: 80%;
    display: flex;
    flex-direction: column;
    position: sticky;
  }
  
  
