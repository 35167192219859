.input-highlight {
    display: none;
}
.tax-jurisdiction-row ion-input {
    height: 3rem;
    min-height: initial !important;
    --highlight-color-focused: black;
    padding-inline-start: 10px !important;
    display: flex;
    flex-direction: row;
}

.tax-name-column ion-input {
    border: solid 1px #EDEDED;
    border-radius: 4px;
    width: 100%;
    margin-right: 10px;
}

.tax-rate-input {
    border: solid 1px #EDEDED;
    border-radius: 4px;
    height: 100%;
    max-width: 107px;
    margin-right: 10px;
    padding-left: 5px;
}

.tax-rate-input:after {
    content: "%";
    font-size: 14px;
    color: #000000;
    margin-left: 5px;
    margin-right: 15px;
    display: flex;
    align-items: center;
}

.taxes-grid {
    margin-top: 30px;
    --ion-grid-padding: 0px;
}

.tax-jurisdiction-header {
    font-weight: bold;
    font-size: 12px;
}

.tax-name-column {
    padding-left: 0px;
}

.tax-rate-column {
    padding-left: 0px;
}

.delete-icon {
    height: 23px;
    width: 23px;
    color: #c5c6c6;
    margin-top: 10px;
    cursor: pointer;
}

.add-icon {
    height: 23px;
    width: 23px;
    margin-right: 5px;
}

.save-taxes-error-popover {
    width: 900px;
    padding: 20px;
}
