.select-setting .select-interface-option {
  --background: #ffffff;
}

ion-item.select-option {
  padding: 0px;
  height: 40px;
  --background: #ffffff;
}

.select-option {
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  padding: 0px;
  --min-height: 30px;
  --background: #ffffff;

}

.terminal-select {
  height: 36px;
  width: 250px;
  border: solid 1px #EDEDED;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  margin: 8px 0px 24px 0px;
  padding-left: 15px;
  --highlight-color-focused: "transparent"
}

ion-select.terminal-select::part(icon) {
  fill: black;
}


.select-setting ion-list {
  background: #ffffff;
  padding: 0px;
}

.select-setting ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}

.drawer-history-page {
  --ion-background-color: #F4F4F6;
  height: calc(100vh - 135px);
}

.drawer-history-grid {
  --ion-grid-columns: 10.25;
  --ion-grid-width-xs: 100%;
  --ion-grid-width-sm: 100%;
  --ion-grid-width-md: 100%;
  --ion-grid-width-lg: 100%;
  --ion-grid-width-xl: 100%;
}

ion-row.drawer-history-grid-header {
  color: #000000;
  height: 40px;
  font-size: 12px;
  font-family: 'Inter-bold';
  letter-spacing: -0.5px;
  line-height: 16px;
  align-items: center;
  background: #ffffff;

  border-bottom-color: #F4F4F6;
  border-bottom-width: 4px;
  border-bottom-style: solid;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;

  padding: 0px 10px;;
}

ion-row.drawer-history-grid-row {
  height: 56px;
  background: #ffffff;
  color: #7F7F7F;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 1px;
  padding: 0px 10px;;
}

ion-row.drawer-history-grid-alt-row {
  height: 56px;
  background: #cccccc50;
  color: #7F7F7F;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 1px;
  padding: 0px 10px;;
}

.tabbedSection {
  padding: 25px;
}

.tabContent::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.drawer-history-header {
  font-weight: bold;
  font-size: 12px;
}

.green-text {
  color: #00C19C;
}

.red-text {
  color: #FA5A5A;
}

ion-col.center-text {
  text-align: center;
}

.ion-button-inner-contents {
  text-align: left;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.bold-text {
  color: #000000;
}

ion-icon.drawer-history-alert {
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: text-top;
  margin-left: -3px;
  fill: red;
}

ion-icon.drawer-history-info {
  height: 16px;
  width: 16px;
  display: inline-block;
  vertical-align: text-top;
  margin-left: -3px;
  fill: blue;
}

ion-icon.drawer-history-info:hover {
  cursor: pointer;
}

.drawer-history-tooltip .drawer-history-tooltiptext {
  visibility: hidden;
  width: 150px;
  height: 50px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 1s;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  display: flex;
  position: absolute;
  z-index: 9999;
  margin-left: -10px;
  margin-top: -75px;
  font-weight: normal;
}

.drawer-history-tooltip .drawer-history-tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.drawer-history-tooltip:hover .drawer-history-tooltiptext {
  opacity: 1;
  visibility: visible;
}
