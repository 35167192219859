.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.ecommerce-account-details {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ecommerce-account-details .rics-button {
  background: #616ae8;
  opacity: 1;
  border: none;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-right: 10px;
}

.ecommerce-account-details .rics-button:hover {
  opacity: 0.86;
  border: none;
  box-shadow: none;
}

.ecommerce-account-details .rics-button:active {
  opacity: 0.76;
  border: none;
  box-shadow: none;
}

.ecommerce-account-details .rics-button:disabled {
  background: #989898;
}

.ecommerce-account-details .account-details-card-container {
  margin-top: 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.ecommerce-account-details .account-details-card {
  background: white;
  padding: 25px;
  border-radius: 8px;
}

.ecommerce-account-details .account-details-card .account-details-card-header {
  border-bottom: 1px solid #efefef;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ecommerce-account-details
  .account-details-card
  .account-details-card-header
  .heading {
  font-size: 20px;
}

.ecommerce-account-details .account-details-card .account-details-card-header .ecommerce-account-button {
  border: none;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: -0.5px;
}

.ecommerce-account-details .account-details-card .account-details-card-header .ecommerce-account-button:hover {
  opacity: 0.9;
}

.ecommerce-account-details .account-details-card .account-details-card-header .enable-account-button {
  background-color: #00c19c;
}

.ecommerce-account-details .account-details-card .account-details-card-header .disable-account-button {
  background-color: #fa5a5a;
}

.ecommerce-account-details .account-details-card .account-details-card-body {
  margin-top: 24px;
}

.ecommerce-account-details
  .account-details-card
  .account-details-card-body
  .row {
  margin-top: 20px;
}

.ecommerce-account-details
  .account-details-card
  .account-details-card-body
  .category-header {
  color: #989898;
}

.ecommerce-account-details
  .account-details-card
  .account-details-card-body
  .category-body
  .row {
  margin-top: 0;
  margin-bottom: 20px;
}

.ecommerce-account-details 
  .account-details-card-body 
  .edit-field-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ecommerce-account-details 
  .account-details-card-body 
  .edit-field-holder 
  .rics-tooltip-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ecommerce-account-details 
  .account-details-card-body 
  .edit-field-holder 
  .rics-tooltip-holder
  .rics-tooltip-img {
  margin-left: 10px;
  filter: invert(42%) sepia(38%) saturate(1274%) hue-rotate(202deg) brightness(96%) contrast(91%);
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .heading {
  font-size: 12px;
  color: #989898;
  margin-bottom: 5px;
  padding: 0;
  height: 18px;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .status-label {
  color: #00c19c;
  align-items: center;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .status-label:not(.active) {
  color: #fa5a5a;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .input-box {
  background-image: none;
  border: 1px solid #d7d7d7;
  height: 43px;
  font-size: 14px;
  letter-spacing: -0.5px;
  padding: 10px;
  outline: none;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .input-box:focus {
    border: 1px solid #616ae8;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .input-box:invalid {
  border-color: #fa5a5a;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .input-box:disabled {
  background-color: #d7d7d7;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .select {
  font-size: 14px;
  letter-spacing: -0.5px;
  border: 1px solid #d7d7d7;
  height: 43px;
  outline: none;
  padding: 10px;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle
  .form-check-input {
  margin: 0;
  padding: 0;
  width: 27px;
  height: 16px;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
      no-repeat,
    #d7d7d7;
  background-position: 0;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle
  .form-check-input:checked {
  background-position: 100%;
  background-color: #616ae8;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle
  .form-check-input:focus {
  box-shadow: none;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle
  .form-check-input:hover {
  cursor: pointer;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle
  .form-check-label {
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-left: 10px;
  color: black;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .image-button {
  background-color: white;
  display: flex;
  align-items: center;
  width: 80px;
  padding-left: 0;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .image-button
  .image-button-img {
  filter: invert(54%) sepia(58%) saturate(5576%) hue-rotate(219deg)
    brightness(92%) contrast(96%);
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .image-button
  .image-button-label {
  color: #616ae8;
  height: 20px;
}

.ecommerce-account-details .account-details-card-container .account-details-card .account-details-card-footer {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #efefef;
}

.ecommerce-account-details .account-details-card-container .account-details-card .account-details-card-footer .account-details-save-button {
  background-color: #616ae8;
  font-size: 13px;
  height: 33px;
  letter-spacing: -0.5px;
  line-height: 20px;
  box-shadow: none;
  border: none;
  padding: 0 1rem;
}

.ecommerce-account-details .account-details-card-container .account-details-card .account-details-card-footer .account-details-save-button:hover {
  opacity: 0.9;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ecommerce-account-details
  .account-details-card-body
  .edit-field-holder
  .toggle-switch
  .toggle-switch-toggle {
    padding: 0;
    margin-right: 10px;
}

.ecommerce-account-details .account-details-card-body .edit-field-holder .toggle-switch .toggle-switch-label {
  font-family: "Inter-Regular";
  font-size: 16px;
  letter-spacing: -0.5px;
}