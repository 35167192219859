.item-card {
    background: #ffffff;
    border-color: #EDEDED;
    height: 92px;
    min-height: 92px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.card-text {
    width: 280px;
    margin-left: 24px;
}

.workflow-img-incomplete {
    fill: #000000;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    width: 21.33px;
    height: 21.33px;
    display: block;
    position: absolute;
    filter: invert(88%) sepia(0%) saturate(0%) hue-rotate(191deg) brightness(85%) contrast(97%);
}

.workflow-img-complete {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    width: 21.33px;
    height: 21.33px;
    display: block;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(200deg) brightness(106%) contrast(101%);
}

.circle-incomplete {
    background: #EDEDED;
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 100%;
    margin-left: 16px;
}

.circle-complete {
    background: #00C19C;
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 100%;
    margin-left: 16px;
}

.card-title {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.card-subtitle {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.card-button {
    height: 32px;
    width: 80px;
    margin-left: 10px;
}

.card-statustext {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.card-status-holder {
    display: flex;
    align-items: center;
    margin-left: auto;
}