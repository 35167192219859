#locationDetailContentHolder{
	background: #f4f4f6;
}

#locationsPageContentInjectionContainer{
	--background: #f4f4f6;
}

.location-details-content {
	max-width: 100%;
}