.privacyPolicyPage {
	min-width: 320px;
	min-height: 480px;
	height: 100%;
	overflow-y: hidden;
	background: #592A60;
}
.privacyPolicyPage .topBarWrapper {
	position: fixed;
	z-index: 9999;
	height: 60px;
	width: 100%;
	top: 0;
	background: white;
	border-bottom: 1px solid rgba(0,0,0,0.25);
	box-shadow: 0 4px 11px 3px rgba(0,0,0,0.25);
}
.privacyPolicyPage .topBarWrapper .innerWrapper {
	display: flex;
	align-items: center;
	margin: auto;
	width: 100%;
	height: 100%;
}
.privacyPolicyPage .topBarWrapper .innerWrapper .portalLogo {
	position: absolute;
	left: 15%;
	height: 35px;
	min-width: 100px;
	margin-right: 10.5%;
}
.privacyPolicyPage .topBarWrapper .innerWrapper .buttonDefault {
	width: 100px;
	transition: all 0.6s;
}
.privacyPolicyPage .topBarWrapper .innerWrapper .buttonHolder {
	position: absolute;
	right: 15%;
	display: flex;
	align-items: center;
	margin-left: auto;
	min-width: 215px;
	overflow: hidden;
}
.privacyPolicyPage .topBarWrapper .innerWrapper .returnButton {
	margin-right: 15px;
	margin-left: 115px;
}
.privacyPolicyPage .privacyPolicy {
	position: relative;
	border-bottom: 1px solid rgba(0,0,0,0.25);
	width: 100%;
	height: 100%;
	margin-top: 60px;
	background: linear-gradient(to right, rgba(2, 15, 24, 0.1) 0%, rgba(2, 15, 24, 1) 90%);
	background-size: cover;
	background-position: center;
}
.privacyPolicyPage .privacyPolicy .privacyPolicyHolder {
	position: absolute;
	width: 80%;
	height: 88%;
	left: 10%;
	background: rgba(0, 0, 0, 0.70);
	color: white;
	margin: 1%;
	padding: 0 1.5rem 1.5rem 1.5rem;
	overflow-y: auto;
}
.privacyPolicyPage .privacyPolicy .privacyPolicyHolder div h1 {
	font-size: calc(1.5rem + 0.3vw);
}
.privacyPolicyPage .privacyPolicy .privacyPolicyHolder div h2 {
	font-size: calc(1rem + 0.3vw);
}
.privacyPolicyPage .privacyPolicy .privacyPolicyHolder .textContent {
	font-size: calc(0.6rem + 0.2vw);
}
.privacyPolicyPage .privacyPolicy .privacyPolicyHolder a {
	color: #616AE8;
}
	.privacyPolicyPage .privacyPolicy .privacyPolicyHolder a:visited {
		color: #616AE8;
	}
.privacyPolicyPage .privacyPolicy .privacyPolicyHolder li:not(:last-child) {
	margin-bottom: 5px;
}