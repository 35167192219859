.create-special-tax-event-modal {
    --width: 37rem;
    --height: initial;
    --background: white;
    display: flex;
}

.special-event-advanced-product-type-column {
    width: 280px;
    border: 1px solid #eee;
}

.special-event-advanced-product-type-column select {
    border: 0px;
    width: 100%;
}

.special-event-advanced-tax-rate-column {
    width: 140px;
    border: 1px solid #eee;
}

.special-event-advanced-tax-rate-column input {
    width: 100%;
    height: 100%;
    border: 0px;
}

.special-event-advanced-delete-column {
    border: 1px solid #eee;
    width: calc(100% - 520);
}

.special-event-advanced-delete-column ion-icon {
    color: #c5c6c6;
    height: 22px;
    width: 22px;
    margin-left: calc(50% - 11px);
    cursor: pointer;
}