.toggle-list-button {
    --border-radius: 4px 0px 0px 4px;
    margin-right: 0px;
    margin-left: 8px;

    --padding-start: 0px;
    --padding-end: 0px;
}

.toggle-cards-button {
    --border-radius: 0px 4px 4px 0px;
    margin-left: 0px;

    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 8px;
    --padding-bottom: 8px;
}

.toggle-view-unselected-button {
    --background: #EDEDED;
    --color: #000000;
}

.toggle-view-selected-button {
    --background: #616AE8;
    --color: #000000;
}

.search-list-button {
    --background: #EDEDED;
    --color: #000000;
    margin-left: 8px;

    --padding-start: 6px;
    --padding-end: 6px;
    --padding-top: 6px;
    --padding-bottom: 6px;
}

.toggle-list-icon {

}

.toggle-cards-icon {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(200deg) brightness(106%) contrast(101%);
}

.toggle-view-selected {
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(200deg) brightness(106%) contrast(101%);
}

.search-list-icon {

}

.square-button {
    height: 32px;
    width: 32px;
}

.square-icon {
    /* font-size: 200px; */
}

/* .search-popover {
    --background: #ffffff;
} */

.show-disabled-users-toggle {
    margin-left: 8px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.show-disabled-users-label {
    margin-right: 0px;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.user-search-input{
    /* box-sizing: border-box; */
    height: 32px;
    width: 225px;
    min-height: 32px !important;
    min-width: 225px !important;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    color: #000000;
    /* padding: 12px; */

    --padding-start: 8px;
    --padding-end: 8px;
    margin-left: 8px;
    margin-right: 0px;
}
