.ecommerce-account-setup-modal {
    font-family: 'Inter-Regular';
}

.ecommerce-account-setup-modal .button {
    background-color: #616ae8;
    letter-spacing: -0.5px;
    font-size: 16px;
    line-height: 24px;
    box-shadow: none;
    border: none;
    padding: 0 1rem;
    height: 40px;
    width: 120px;
    border-radius: 4px;
    margin: 0 10px 0 10px;
}

.ecommerce-account-setup-modal .button:hover,
.ecommerce-account-setup-modal .button:focus {
    opacity: 0.9;
    background-color: #616ae8;
    box-shadow: none;
    border: none;
}

.ecommerce-account-setup-modal .card-button {
    background: transparent;
    width: 224px;
    height: 164px;
    border: 1px solid #D8D8D8;
    box-shadow: none;
    margin: 10px;
    padding: 0;
}

.ecommerce-account-setup-modal .card-button .image {
    height: 28px;
    width: 28px;
    margin-bottom: 24px;
    filter: invert(45%) sepia(45%) saturate(934%) hue-rotate(200deg) brightness(90%) contrast(102%);
}

.ecommerce-account-setup-modal .card-button:hover .image,
.ecommerce-account-setup-modal .card-button:focus .image {
    filter: invert(98%) sepia(99%) saturate(0%) hue-rotate(69deg) brightness(112%) contrast(100%);
}

.ecommerce-account-setup-modal .card-button .title {
    color: black;
    font-size: 16px;
    text-align: center;
}

.ecommerce-account-setup-modal .card-button:hover .title,
.ecommerce-account-setup-modal .card-button:focus .title {
    color: white;
}

.ecommerce-account-setup-modal .card-button .sub-title {
    color: #D8D8D8;
    font-size: 14px;
    text-align: center;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header
.ecommerce-account-setup-modal-header-top-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header
.ecommerce-account-setup-modal-header-top-row
.ecommerce-account-setup-modal-header-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 24px 24px 0 0;
    height: 16px;
    width: 16px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header
.ecommerce-account-setup-modal-header-top-row
.ecommerce-account-setup-modal-header-close-btn
.ecommerce-account-setup-modal-header-close-btn-img {
    position: absolute;
    top: 0;
    right: 0;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header
.ecommerce-account-setup-modal-header-top-row
.ecommerce-account-setup-modal-header-logo {
    height: 70px;
    margin: 40px auto 24px auto;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header
.ecommerce-account-setup-modal-header-title {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    margin-bottom: 16px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-header
.ecommerce-account-setup-modal-header-sub-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
    text-align: center;
    padding-left: 100px;
    padding-right: 100px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body {
    padding: 24px 40px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-row {
    display: flex;
    flex-direction: row;
    width: 664px;
    margin-left: auto;
    margin-right: auto;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-row
.ecommerce-account-setup-modal-body-col {
    width: 50%;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-section-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    width: 320px;
    height: 72px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-section-holder
.ecommerce-account-setup-modal-body-section-holder-image-holder {
    background-color: #00000012;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-section-holder
.ecommerce-account-setup-modal-body-section-holder-image-holder
.ecommerce-account-setup-modal-body-section-holder-image {
    height: 21px;
    width: 21px;
    filter: invert(0%) sepia(100%) saturate(7459%) hue-rotate(59deg) brightness(89%) contrast(111%);
    opacity: 25%;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-section-holder
.ecommerce-account-setup-modal-body-section-holder-text {
    width: 248px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-section-holder
.ecommerce-account-setup-modal-body-section-holder-text
.ecommerce-account-setup-modal-body-section-holder-text-title {
    font-family: 'Inter-Bold';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.5px;
    margin-bottom: 8px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-section-holder
.ecommerce-account-setup-modal-body-section-holder-text
.ecommerce-account-setup-modal-body-section-holder-text-sub-title {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px 40px 40px 40px;
}

.ecommerce-account-setup-modal
.next-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ecommerce-account-setup-modal
.next-button
.next-button-image {
    filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(183deg) brightness(103%) contrast(100%);
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-auth-button-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body-continue-button-holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body {
    width: 80%;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-edit-field-holder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-edit-field-holder
.ecommerce-account-setup-modal-body-edit-field-holder-header {
    font-size: 12px;
    color: #989898;
    margin-bottom: 5px;
    padding: 0;
    height: 18px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-edit-field-holder
.ecommerce-account-setup-modal-body-edit-field-holder-input {
    background-image: none;
    border: 1px solid #d7d7d7;
    height: 43px;
    font-size: 14px;
    letter-spacing: -0.5px;
    box-shadow: none;
    outline: none;
    padding: 10px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-edit-field-holder
.ecommerce-account-setup-modal-body-edit-field-holder-input:invalid {
    border-color: #fa5a5a;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-edit-field-holder
.ecommerce-account-setup-modal-body-edit-field-holder-input:focus {
    border: 2px solid #616ae8;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-edit-field-holder
.edit-field-holder-select {
    font-family: 'Inter-Regular';
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-code {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-code
.ecommerce-account-setup-modal-email-verification-digit {
    box-shadow: none;
    border: 1px solid #989898;
    width: 50px;
    text-align: center;
    height: 60px;
    padding: 10px;
    font-size: 40px;
    margin: 10px;
    outline: none;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-code
.ecommerce-account-setup-modal-email-verification-digit:focus {
    border: 2px solid #616ae8;
}

.ecommerce-account-setup-modal-email-verification-digit::-webkit-outer-spin-button,
.ecommerce-account-setup-modal-email-verification-digit::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-footer
.resend-btn {
    background-color: transparent;
    color: #616ae8;
    border: none;
    box-shadow: none;
    margin-top: 10px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-footer
.resend-btn:hover {
    text-decoration: underline;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-footer
.verified {
    color: #00C19C;
    margin-bottom: 10px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.ecommerce-account-setup-modal-email-verification
.ecommerce-account-setup-modal-email-verification-footer
.not-verified {
    color: #FA5A5A;
    margin-bottom: 10px;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.select-fulfillment-strategy
.list-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;
}

.select-fulfillment-strategy .fulfillment-strategy-item {
    height: 230px;
    width: 260px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-fulfillment-strategy .fulfillment-strategy-item:hover,
.select-fulfillment-strategy .fulfillment-strategy-item:focus {
    background-color: #616ae8;
}

.select-fulfillment-strategy .fulfillment-strategy-item .image {
    margin-top: 24px;
}

.select-fulfillment-strategy .fulfillment-strategy-item-active {
    background-color: #616ae8;
    height: 230px;
    width: 260px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select-fulfillment-strategy .fulfillment-strategy-item-active .image {
    margin-top: 24px;
    margin-bottom: 24px;
    height: 28px;
    width: 28px;
    filter: invert(98%) sepia(99%) saturate(0%) hue-rotate(69deg) brightness(112%) contrast(100%);
}

.select-fulfillment-strategy .fulfillment-strategy-item-active .title {
    color: white;
}

.locations-list-group
.locations-list-group-item {
    border: 1px solid #D8D8D8;
    background: transparent;
}

.locations-list-group .locations-list-group-item:hover,
.locations-list-group .locations-list-group-item:focus {
    background-color: #616ae8;
    color: white;
}

.ecommerce-account-setup-modal
.ecommerce-account-setup-modal-body
.ecommerce-account-setup-modal-body-container
.locations-setup {
    width: 100%;
}