.integrations-content {
  --background: #f4f4f6;
  margin-left: 10%;
  --padding-bottom: 200px;
}

.integrations-content {
  position: relative;
  overflow-y: scroll;
  padding-bottom: 70px;
}

.integrations-content .integrations-layout-container {
  width: 80%;
  margin: 0 auto;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item {
  background-color: transparent;
  border: none;
  margin-bottom: 24px;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item
  .integrations-accordion-header {
  font-size: 20px;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item
  .integrations-accordion-header
  .accordion-button {
  color: initial;
  background-color: #ededed;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item
  .integrations-accordion-header
  .accordion-button {
  box-shadow: initial;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item
  .integrations-accordion-header
  .accordion-button::after {
  color: black;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item
  .integrations-accordion-header
  .accordion-button:not(.collapsed)::after {
  color: black;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-item
  .integrations-accordion-body {
  padding: 0;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-inner
  .integrations-accordion-item {
  margin-bottom: 0;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-inner
  .integrations-accordion-item
  .integrations-accordion-header {
  font-size: 16px;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-inner
  .integrations-accordion-item
  .integrations-accordion-header
  .accordion-button {
  color: initial;
  background-color: transparent;
}

.integrations-content
  .integrations-layout-container
  .integrations-accordion-inner
  .integrations-accordion-item
  .integrations-accordion-body {
  display: flex;
  flex-wrap: wrap;
}

.integration-card {
  width: 360px;
  height: 308px;
  background: white;
  margin: 24px 24px 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.integration-card:hover {
  cursor: default;
}

.integration-card:not(.disabled):hover {
  cursor: pointer;
}

.integration-card .integration-card-content {
  height: 260px;
  width: 312px;
  display: flex;
  flex-direction: column;
}

.integration-card .integration-card-content .integration-card-image-row {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  align-items: flex-start;
}

.integration-card
  .integration-card-content
  .integration-card-image-row
  .integration-card-icon {
  height: 24px;
  width: 24px;
  position: absolute;
}

.integration-card
  .integration-card-content
  .integration-card-image-row
  .integration-card-icon
  .integration-card-icon-circle {
  height: 16px;
  width: 16px;
  background-color: #00c19c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.integration-card
  .integration-card-content
  .integration-card-image-row
  .integration-card-icon
  .integration-card-icon-check {
  height: 6.8px;
  width: 8.8px;
  filter: invert(99%) sepia(3%) saturate(155%) hue-rotate(320deg)
    brightness(116%) contrast(100%);
}

.integration-card
  .integration-card-content
  .integration-card-image-row
  .integration-card-logo-holder {
  margin: auto;
  height: 88px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-card
  .integration-card-content
  .integration-card-image-row
  .integration-card-logo-holder
  .integration-card-logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.integration-card .integration-card-content .integration-card-details-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.integration-card .integration-card-content .integration-card-name {
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.integration-card .integration-card-content .integration-card-description {
  font-size: 14px;
  color: #8c8e8f;
  word-wrap: normal;
}

.integration-card .integration-card-content .integration-card-configure-row {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 24px;
}

.integration-card
  .integration-card-content
  .integration-card-configure-row
  .integration-card-configure-holder {
  width: 93px;
  height: 32px;
  color: white;
  background-color: #616ae8;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-card
  .integration-card-content
  .integration-card-configure-row
  .integration-card-configure-holder
  .integration-card-configure-text {
  font-size: 14px;
}

.integration-card
  .integration-card-content
  .integration-card-configure-row
  .integration-card-add-holder {
  width: 70px;
  height: 32px;
  background-color: #ededed;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-card
  .integration-card-content
  .integration-card-configure-row
  .integration-card-add-holder
  .integration-card-add-text {
  font-size: 14px;
  color: black;
}

.integration-card
  .integration-card-content
  .integration-card-configure-row
  .integration-card-coming-soon-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.integration-card
  .integration-card-content
  .integration-card-configure-row
  .integration-card-coming-soon-holder
  .integration-card-coming-soon-text {
  font-size: 14px;
  color: black;
}

.integrations-pro-badge-holder {
  background-color: #30B6FF;
  width: 28px;
  height: 16px;
  border-radius: 4px;
}

.integrations-pro-badge-holder
  .integrations-pro-badge {
  color: #FFFFFF;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.5px;
  text-align: center;
}

.integrations-no-access-modal .integrations-no-access-modal-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  border-bottom: none;
}

.integrations-no-access-modal .integrations-no-access-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center; 
  border-top: none;
}

.integrations-no-access-modal .integrations-no-access-modal-footer .integrations-no-access-modal-ok-button {
  background-color: #616ae8;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 20px;
  box-shadow: none;
  border: none;
  padding: 0.5rem 1rem;
  width: 120px;
}

.integrations-no-access-modal .integrations-no-access-modal-footer .integrations-no-access-modal-ok-button:hover {
  opacity: 0.9;
}