/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-text-color: #000000;
  --ion-text-color-rgb: 255,255,255;

  --ion-background-color: #F4F4F6;
  --ion-background-color-rgb: 244,244,246;

  --ion-font-family: 'Inter-Regular', Arial, Helvetica, sans-serif;

  --ion-item-background: #efefef;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-border-color: #222222;

  /** primary **/
  --ion-color-primary: #616AE8;
  --ion-color-primary-rgb: 97,106,232;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #555dcc;
  --ion-color-primary-tint: #7179ea;


  /** secondary **/
  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80,200,255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  /** tertiary **/
  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  /** success **/
  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  /** warning **/
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  /** danger **/
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  /** dark **/
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;


  /** light **/
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34,36,40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255,255,255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  /** gray **/
  --ion-color-gray: #989898;
  --ion-color-gray-rgb: 152,152,152;
  --ion-color-gray-contrast: #000000;
  --ion-color-gray-contrast-rgb: 0,0,0;
  --ion-color-gray-shade: #868686;
  --ion-color-gray-tint: #a2a2a2;

  --ion-color-complete: #00C19C;
  --ion-color-complete-rgb: 0,193,156;
  --ion-color-complete-contrast: #ffffff;
  --ion-color-complete-contrast-rgb: 255,255,255;
  --ion-color-complete-shade: #00aa89;
  --ion-color-complete-tint: #1ac7a6;

  --ion-color-button: #616AE8;
  --ion-color-button-rgb: 97,106,232;
  --ion-color-button-contrast: #ffffff;
  --ion-color-button-contrast-rgb: 255,255,255;
  --ion-color-button-shade: #555dcc;
  --ion-color-button-tint: #7179ea;

  --ion-color-testbutton: #000000;
  --ion-color-testbutton-rgb: 0,0,0;
  --ion-color-testbutton-contrast: #ffffff;
  --ion-color-testbutton-contrast-rgb: 255,255,255;
  --ion-color-testbutton-shade: #ff0000;
  --ion-color-testbutton-tint: #00ff00;

  --ion-color-lightgray: #EFEFEF;
  --ion-color-lightgray-rgb: 239,239,239;
  --ion-color-lightgray-contrast: #000000;
  --ion-color-lightgray-contrast-rgb: 0,0,0;
  --ion-color-lightgray-shade: #d2d2d2;
  --ion-color-lightgray-tint: #f1f1f1;

  --rics-tertiary-color: #ff6262;
  --rics-tertiary-color-shade: #de5454;
}



ion-popover {
  --backdrop-opacity: 0.6;
}

ion-popover::part(backdrop) :not(.select-setting) {
  background-color: #1e1e1e;
}

ion-button {
    text-transform: none;
}

.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.ion-color-lightgray {
  --ion-color-base: var(--ion-color-lightgray);
  --ion-color-base-rgb: var(--ion-color-lightgray-rgb);
  --ion-color-contrast: var(--ion-color-lightgray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightgray-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightgray-shade);
  --ion-color-tint: var(--ion-color-lightgray-tint);
}

.ion-color-complete {
  --ion-color-base: var(--ion-color-complete);
  --ion-color-base-rgb: var(--ion-color-complete-rgb);
  --ion-color-contrast: var(--ion-color-complete-contrast);
  --ion-color-contrast-rgb: var(--ion-color-complete-contrast-rgb);
  --ion-color-shade: var(--ion-color-complete-shade);
  --ion-color-tint: var(--ion-color-complete-tint);
}

.ion-color-button {
  --ion-color-base: var(--ion-color-button);
  --ion-color-base-rgb: var(--ion-color-button-rgb);
  --ion-color-contrast: var(--ion-color-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-button-shade);
  --ion-color-tint: var(--ion-color-button-tint);
}

.ion-color-testbutton {
  --ion-color-base: var(--ion-color-testbutton);
  --ion-color-base-rgb: var(--ion-color-testbutton-rgb);
  --ion-color-contrast: var(--ion-color-testbutton-contrast);
 --ion-color-contrast-rgb: var(--ion-color-testbutton-contrast-rgb);
  --ion-color-shade: var(--ion-color-testbutton-shade);
  --ion-color-tint: var(--ion-color-testbutton-tint);
}
