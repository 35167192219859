.scrollSpyContainer {
    position: fixed;
    background-color: white;
    width: 264px;
    border-radius: 4px;
  }
  
  .spyItemClass {
    --ion-background-color: #ffffff;
    --ion-item-background: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    --min-height: 40px;
    border-radius: 4px;
  }
  
  .spyItemClass:hover {
    cursor: pointer;
  }
  
  .active {
    --ion-background-color: #616AE8;
    --ion-item-background: #616AE8;
    color: white;
  }
  
  .scroll-spy-arrow {
    height: 16px;
    width: 16px;
    color: white;
  }