ion-img.branding-image {
    width: 160px;
    height: 90px;
    object-fit: contain;
    cursor: pointer;
}

.file-upload {
    display: none;
}

.branding-delete-button {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: black;
    cursor: pointer;
    color: #ffffff;
}

.branding-delete-button polygon {
    fill: white;
}

.image-upload {
    position: relative;
    width: 180px;
    height: 110px;
    margin-top: 20px;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
}

.section {
    margin-top: 10px;
    margin-bottom: 10px;
}

.receipt-text {
    font-size: 12px;
    letter-spacing: -0.5px;
    line-height: 16px;
}

.receipt-text ion-textarea {
    height: 84px;
    width: 696px;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    outline: none;
    min-height: initial !important;
    --padding-top: 10px !important;
    --padding-bottom: 10px !important;
    --highlight-color-focused: black;
}

.receipt-text ion-textarea .textarea-highlight {
    display: none;
}

.receipt-text ion-textarea textarea {
    padding-inline-start: 10px !important;
}

.sub-header {
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
}

.branding-valid {
    border: 1px solid #efefef;
}

.branding-invalid {
    border: 1px solid #ff0000 !important;
}