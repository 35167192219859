/* All Locations */
.all-locations-content {
    --background: #F4F4F6;
}

.locations-page {
    height: calc(100vh - 100px);
}

/* Locations Grid */
.all-locations-grid {
    --ion-grid-width-xs: 100%;
    --ion-grid-width-sm: 100%;
    --ion-grid-width-md: 100%;
    --ion-grid-width-lg: 100%;
    --ion-grid-width-xl: 100%;
}

ion-row.location-grid-row {
    height: 56px;
    background: #ffffff;
    color: #7F7F7F;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    align-items: center;
    margin-bottom: 1px;
    user-select: text;
}

.gridPanel .locationImageHolder ion-img {
    height: 72px;
    width: 72px;
    object-fit: cover;
    object-position: 50% 50%;
    max-width: none;
}

.progressIconHolder ion-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 13px;
    filter: brightness(0%) invert(1);
}

.progressIconHolder ion-img.done {
    filter: brightness(0%) invert(1);
}

.progressIconHolder ion-img.notdone {
    filter: brightness(0%);
    opacity: 0.1;
}

.finishAndInviteButton{
    background: #592A60;
    right: 0;
    padding-right: 0;
    padding-left: 15px;
}

.finishAndInviteButton:hover{
    background: #421f47;
}

.finishAndInviteButton ion-img{
    margin-left: 10px;
    margin-right: 10px;
    height: 10px;
}

ion-col .locationImageHolder {
    background: #efefef;
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #efefef;
    height: 45px;
    width: 45px;
    overflow: hidden;
    margin: 0 auto;
}

.locations-grid {
    --ion-grid-columns: 12;
}

.storeContactIcon{
    height: 12px;
    width: 12px;
    filter: invert(67%) sepia(69%) saturate(7029%) hue-rotate(221deg) brightness(95%) contrast(92%);
    margin-right: 5px;
    margin-top: 2px;
}

.location-status-item {
    --background: #C0C0C0;
    --min-height: 24px;
    --border-radius: 4px;
    font-size: 12px;
    width: fit-content;
    --padding-end: 8px;
    --padding-start: 8px;
    --inner-padding-end: 0px;
    text-align: center;
    --color: white;
    letter-spacing: -0.5px;
    line-height: 16px;
    text-transform: capitalize;
}

.location-status-item.active {
    --background: #00C19C;
}

.location-status-label {
    margin: 0px;
}

.storeContactHolder {
    position: relative;
    display: inline-flex;
    background: #edfafd;
    color: #616AE8;
    height: 24px;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px 9px 0px 5px;
}

/* Copied from old code */

#modalBackground {
	display: none;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	background: rgba(0, 0, 0, 0.85);
	z-index: 9999999;
}

#modalContainer {
	min-width: 320px;
	height: auto;
	border-radius: 10px;
	background: white;
	box-shadow: 0px 0px 20px 6px;
}

#modalTitle {
	display: flex;
	align-items: center;
	color: white;
	padding: 15px;
	font-size: 18px;
	width: 100%;
	height: auto;
	background: #085889;
	border-radius: 8px 8px 0 0;
}

#modalExit {
	display: flex;
	margin-left: auto;
	width: 20px;
	height: 30px;
	cursor: pointer;
}

#modalExit svg {
    width: 100%;
    height: 70%;
}

#modalExit svg polygon {
    fill: white;
}

#modalBody {
	position: relative;
}

#modalBody .contentExpander {
    clear: both;
}

#modalBody.large {
    width: 1200px;
}

#modalBody .simpleTextModal {
    padding: 40px;
}

#modalFooter {
	position: relative;
	height: auto;
	width: 100%;
	top: calc(100% - 135px);
	padding: 0 15px 15px 15px;
	display: flex;
	align-items: center;
}

#modalFooter .buttonDefault {
    margin: 0 5px;
}

#modalFooter .buttonDefault:first-child {
    margin-left: auto;
}

#genericInProgressForm {
	height: 200px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}

#genericInProgressForm .topText {
    text-align: center;
    height: 20%;
}

#genericInProgressForm .bottomText {
    text-align: center;
    height: 30%;
}


#genericInProgressForm .loadingWrapper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#genericInProgressForm .loadingWrapper .loadingCircle {
    border: 4px solid #EDEDED;
    border-radius: 50%;
    border-top: 4px solid #616AE8;
    width: 35px;
    height: 35px;
    -moz-animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    margin-right: 15px;
}

#finishAndInviteModal {
	position: relative;
	vertical-align: middle;
	text-align: center;
	margin-top: 25px;
	padding: 0 35px 0 35px;
}

#finishAndInviteModal .topRow {
    margin-top: 35px;
}

#finishAndInviteModal .heading {
    font-size: 24px;
    margin-top: 40px;
}

#finishAndInviteModal .subHeading {
    font-size: 16px;
    margin-top: 20px;
}

#finishAndInviteModal .bottomRow {
    position: relative;
    padding-top: 40px;
    text-align: center;
    height: 120px;
}

#finishAndInviteModal .bottomRow > * {
    padding: 0 15px;
    height: 40px;
    margin-right: 20px;
}

#finishAndInviteModal .bottomRow .finishAndInviteButton {
    background: #616AE8;
    right: 0;
    padding-right: 0;
    padding-left: 15px;
}

#finishAndInviteModal .bottomRow .finishAndInviteButton svg {
    margin-left: 10px;
    height: 10px;
}

#finishAndInviteModal .bottomRow .finishAndInviteButton.disabled {
    background: rgba(97, 106, 232, 0.5);
    color: rgba(255, 255, 255, 0.5);
}

#finishAndInviteModal .bottomRow .finishAndInviteButton.disabled svg path {
    color: rgba(255, 255, 255, 0.5);
}

#finishAndInviteModal .bottomRow .cancelButton {
    color: black;
    background: #efefef;
    left: 0;
}

#finishAndInviteModal .exitButton {
    position: absolute;
    right: 30px;
    top: -7px;
    height: 20px;
    width: 20px;
    cursor: pointer;
}



#locationSection {
	position: relative;
	overflow-y: scroll;
	padding-bottom: 70px;
}

#locationSection #locationsLayoutContainer {
    width: 80%;
    margin: 0 auto;
}

#locationSection #locationGrid {
    /* padding: 10px 0px; */
    padding: 0px;
    margin-left: -10px;
}

#locationSection #locationGrid .heading {
    font-size: 20px;
    margin: 10px;
}

#locationSection #locationGrid .gridPanel {
    display: inline-block;
    vertical-align: top;
    width: 264px;
    background: white;
    border-radius: 8px;
    margin: 10px;
}

#locationSection #locationGrid .gridPanel:hover {
    cursor: pointer;
}

#locationSection #locationGrid .gridPanel .content {
    text-align: center;
    padding: 20px;
}

#locationSection #locationGrid .gridPanel .content .locationImageRow {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}

#locationSection #locationGrid .gridPanel .content .locationImageHolder {
    display: inline-block;
    position: relative;
    background: #efefef;
    border-radius: 100%;
    height: 72px;
    width: 72px;
    overflow: hidden;
}

#locationSection #locationGrid .gridPanel .content .locationImageHolder > svg {
    position: absolute;
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

#locationSection #locationGrid .gridPanel .content .locationImageHolder > img {
    width: 72px;
    height: 72px;
    object-fit: cover;
    object-position: 50% 50%;
    max-width: none;
}

#locationSection #locationGrid .gridPanel .content .heading {
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#locationSection #locationGrid .gridPanel .content .subHeading {
    font-size: 14px;
    text-transform: capitalize;
    color: #8c8e8f;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 16px;
}

#locationSection #locationGrid .gridPanel .content .storeContactHolder {
    position: relative;
    display: inline-block;
    background: #edfafd;
    color: #616AE8;
    height: 24px;
    font-size: 12px;
    margin-top: 30px;
    border-radius: 4px;
    padding: 4px 9px 0px 5px;
}

#locationSection #locationGrid .gridPanel .content .storeContactHolder > * {
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 175px;
}

#locationSection #locationGrid .gridPanel .content .storeContactHolder .storeContactIcon {
    height: 12px;
    margin-right: 5px;
    margin-top: -2px;
}

#locationSection #locationGrid .gridPanel .content .storeContactHolder .storeContactIcon path {
    fill: #616AE8;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder {
    height: 61px;
    margin-top: 20px;
    padding-top: 15px;
    border-top: 1px solid #efefef;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBarTopText {
    opacity: 1;
    color: #8c8e8f;
    font-size: 12px;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBar {
    display: inline-block;
    margin-top: 10px;
}

.progressIconHolder {
    position: relative;
    display: inline-block;
    margin-right: 7px;
    border: 1px solid #efefef;
    border-radius: 100%;
    height: 21px;
    width: 21px;
}

.progressIconHolder.done {
    background: #30B6FF;
    border: 1px solid #30B6FF;
}

.progressIconHolder svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 13px;
}

.progressIconHolder svg.done path {
    fill: white;
}

.progressIconHolder svg.notdone path {
    fill: #efefef;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBar .finishAndInviteButton {
    background: #592A60;
    right: 0;
    padding-right: 0;
    padding-left: 15px;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBar .finishAndInviteButton:hover {
    background: #421F47;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBar .finishAndInviteButton svg {
    margin-left: 10px;
    height: 10px;
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBar .finishAndInviteButton.disabled {
    background: rgba(89, 42, 96, 0.5);
    color: rgba(255, 255, 255, 0.5);
}

#locationSection #locationGrid .gridPanel .content .progressBarHolder .progressBar .finishAndInviteButton.disabled svg path {
    color: rgba(255, 255, 255, 0.5);
}

#locationSection .noState {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: #e4e4e6;
    width: 95%;
    height: calc(95% - 200px);
}

#locationSection .noState .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
}

#locationSection .noState .content .iconHolder {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: white;
    border-radius: 50%;
    margin-bottom: 25px;
}

#locationSection .noState .content .iconHolder ion-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 45%;
    width: 45%;
    filter: invert(40%) sepia(91%) saturate(1645%) hue-rotate(218deg) brightness(91%) contrast(100%);
}

#locationSection .noState .content .heading {
    font-size: 24px;
    font-weight: bold;
}

#locationSection .noState .content .subHeading {
    font-size: 14px;
    margin-top: 10px;
}

#locationSection .noState .content .buttonHolder {
    display: inline-block;
    margin-top: 25px;
}

#locationSection .noState .content .buttonHolder #importButton {
    background: white;
    color: black;
    margin-right: 20px;
    vertical-align: top;
}

.finish-and-invite-users-modal{
    --min-width: 320px;
    --width: 800px;
    --height: 500px;
    --border-radius: 10px;
}

.finish-and-invite-image {
    width: 256px;
    height: 162px;
    margin: auto;
}