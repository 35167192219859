.rics-menu-title{
    --color: #ffffff;
    --background: #252525;
}

.rics-menu{
    --side-width: 264px;
    min-width: 0px;
    --background: #252525;
    --color: white;
    border-right-width: 0px;
    --ion-item-background: #252525;
}

.rics-menu ion-list{
    padding-top: 0;
    padding-bottom: 0;
}

.rics-menu-item{
    --background: #252525;
    --color: #ffffff;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    --min-height: 40px;
}

.rics-menu-img {
    width: 16px;
    min-width: 16px;
    height: 16px;
    fill: #929292;
    margin: 4px 0px 4px 4px;
}

.rics-menu-label {
    margin-left: 20px;
}


ion-menu ion-item.selected {
    --color: white;
    --detail-icon-opacity: 1.0;
    --background: #343434;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    --min-height: 40px;
}

ion-menu ion-item.disabled {
    background: #202020;
    --background: #202020;

}

ion-menu ion-item.selected ion-icon {
    fill: white;
    opacity: 1.0;
}

.rics-menu-select .select-interface-option {
    --color: #ffffff;
    --background: #282828;
}

.rics-menu-select ion-list {
    background: #282828;
}

#locationPicker {
    --padding-start: 0px;
}

ion-select.locationPicker::part(icon) {
    fill: white;
    opacity: 1.0;
}

#collapsedLocationPicker{
    padding: 0px;
}

#collapsedLocationPicker::part(text) {
    display: none;
}

#collapsedLocationPicker::part(placeholder) {
    display: none;
}

/* hides the radio buttons automatically added by ionic */
ion-select-popover ion-list ion-item ion-radio {
    --color: transparent;
    --color-checked: transparent;
}