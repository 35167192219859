.rics-side-menu-tab-header .header-toolbar {
  --background: #ffffff;
  /* todo: check why the other content was 40px total when I think they should both be 24px */
  --padding-start: 24px;
}

.rics-side-menu-tab-header .header-icon {
  fill: #ffffff;
  opacity: 1;
  height: 50%;
  width: 50%;
  transform: translate(50%, 50%);
}

.rics-side-menu-tab-header .header-circle {
  background: #616ae8;
  width: 32px;
  height: 32px;
  position: relative;
  border-radius: 100%;
}

.rics-side-menu-tab-header .header-title {
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 28px;
  padding-left: 16px;
}
