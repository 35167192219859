ion-toggle {
  --track-background: #cccccc;
  --track-background-checked: #616ae8;
  --handle-background: #ffffff;
  --handle-background-checked: #ffffff;
  width: 40px;
  height: 16px;
  --handle-width: 10px;
  --handle-height: 10px;
  --handle-box-shadow: 0 0 0 0;
  --handle-spacing: 3px;
  --handle-transition: transform 0.4s;
  padding-left: 0px;
  margin-right: 5px;
}

.locations-override {
  --ion-background-color: #ffffff;
  --ion-item-background: #ffffff;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  --padding-start: 0px;
  overflow: visible;
  display: flex;
}

.refund-settings {
  --ion-background-color: #ffffff;
  --ion-item-background: #ffffff;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  --padding-start: 0px;
  overflow: visible;
  display: flex;
}

.locations-override-toggle {
  padding-top: 0px;
  padding-bottom: 0px;
}

.refund-settings-toggle {
  padding-top: 0px;
  padding-bottom: 0px;
}

.settings-page {
  --ion-background-color: #f4f4f6;
  height: calc(100vh - 100px);
  top: 24px;
}

.settings-page-tenant {
  --ion-background-color: #f4f4f6;
  height: calc(100vh - 135px);
}

.scroll-margin {
  margin-top: 100px;
  height: 100%;
}

ion-item.setting {
  --padding-start: 0px;
  --ion-background-color: #ffffff;
  --ion-item-background: #ffffff;
  height: 35px;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  display: flex;
}

ion-item.setting:hover {
  cursor: pointer;
}

ion-item.setting > ion-radio {
  margin-left: 4px;
  margin-right: 8px;
  width: initial;
  --color: var(--ion-color-step-400, #999999);
}

.settings-alert-holder {
  height: 64px;
  margin-top: -5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.settings-alert-cover {
  height: 60px;
  width: 1075px;
  background: rgb(244, 244, 246);
  z-index: 5;
  position: fixed;
}

.settings-alert {
  border-radius: 4px;
  --ion-background-color: #616ae8;
  --ion-item-background: #616ae8;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  color: #ffffff;
  --min-height: 36px;
  width: 1075px;
  position: fixed;
  margin-top: 5px;
  --inner-border-width: 0 0 0 0;
}

.settings-grid {
  width: 1000px;
}

.payment-details-radio-buttons ion-radio {
  margin-right: 15px;
  height: 20px;
  width: 20px;
}

.select-setting .select-interface-option {
  --background: #ffffff;
}

ion-item.select-option {
  padding: 0px;
  height: 40px;
}

.select-option {
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  padding: 0px;
  --min-height: 30px;
}

.select-setting .select-option {
  --color-hover: #ffffff;
  --background-hover: #1e90ff;
  --background-hover-opacity: 100;
}

.return-reason-setting {
  height: 44px !important;
}

.return-reason-select {
  outline: none;
  padding-left: 16px;
  height: 36px;
  width: 190px;
  border: solid 1px #ededed;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
}

.return-reason-column {
  width: 50%;
  display: inline-flex;
}

.select-setting ion-list {
  background: #ffffff;
  padding: 0px;
}

.select-setting ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}

.muted {
  opacity: 0.25;
}

ion-button.save-setting {
  font-size: 13px;
  height: 33px;
  letter-spacing: -0.5px;
  margin: 40px 0 0 0;
}

.contentSection {
  padding: 25px;
  border-radius: 8px;
  background: #ffffff;
  width: 744px;
  margin-bottom: 10px;
}

.settings-card-heading {
  font-size: 20px;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
}

.sub-heading-wrapper {
  margin-top: 8px;
}

.sub-heading {
  font-size: 14px !important;
  letter-spacing: -0.5px;
}

.left-right-content-holder {
  margin-top: 30px;
  text-align: left;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  --ion-grid-padding: 0px;
}

.left-heading-content {
  --ion-grid-column-padding: 0px;
}

.right-preamble-content {
  --ion-grid-column-padding: 0px;
}

.right-content {
  padding-top: 12px;
}

ion-icon.info {
  filter: invert(74%) sepia(5%) saturate(0%) hue-rotate(232deg) brightness(104%)
    contrast(90%);
  padding-top: 5px;
}

ion-icon.info:hover {
  cursor: pointer;
  filter: brightness(0) saturate(100%);
}

.locations-can-overrride-tooltip {
  position: absolute;
  display: inline;
  margin-left: 190px;
}

.refund-settings-tooltip {
  position: absolute;
  display: inline;
  margin-left: 215px;
}

.other-tipping-option-tooltip{
  position: absolute;
  display: inline;
  margin-left: 220px;
}

.locations-can-overrride-tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  height: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 1s;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  display: flex;
  position: absolute;
  margin-left: -113px;
  margin-top: -105px;
}

.locations-can-overrride-tooltip .modaltooltiptext {
  visibility: hidden;
  width: 220px;
  height: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 1s;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  display: flex;
  position: absolute;
  z-index: 100000;
  margin-left: -220px;
  margin-top: 10px;
}

.refund-settings-tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  height: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 1s;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  display: flex;
  position: absolute;
  z-index: 100;
  margin-left: -113px;
  margin-top: -105px;
}

.other-tipping-option-tooltip .tooltiptext{
  visibility: hidden;
  width: 220px;
  height: 80px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 1s;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  display: flex;
  position: absolute;
  margin-top: -110px;
  margin-left: -114px;
}

.locations-can-overrride-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 55%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.refund-settings-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 55%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.other-tipping-option-tooltip .tooltiptext::after{
  content: " ";
  position: absolute;
  top: 100%;
  left: 55%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.locations-can-overrride-tooltip:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.locations-can-overrride-tooltip:hover .modaltooltiptext {
  opacity: 1;
  visibility: visible;
}

.refund-settings-tooltip:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.other-tipping-option-tooltip:hover .tooltiptext{
  opacity: 1;
  visibility: visible;
}

.locations-override-info {
  font-size: 14px;
  height: 20px;
  width: 140px;
  line-height: 20px;
}

.refund-settings-info {
  font-size: 14px;
  height: 20px;
  width: 170px;
  line-height: 20px;
}

.other-tipping-option-info{
  font-size: 14px;
  height: 20px;
  width: 170px;
  line-height: 20px;
}

.locations-can-overrride-tooltip-holder {
  width: 204px;
}

.refund-settings-tooltip-holder {
  margin-right: 30px;
}

.other-tipping-option-tooltip-holder{
  margin-right: 30px;
}

.icon-icon-arrow-right {
  height: 16px;
  width: 16px;
  fill: #ffffff;
}

.modal-buttons-holder {
  margin-top: 35px;
}

.modal-back-button {
  --background: #ededed;
  --color: #000000;
}

#main {
  width: 80%;
}

.settings-grid-container {
  margin-bottom: 20px;
}

.apply-discounts-optional-text {
  opacity: 0.5;
  color: #000000;
  font-size: 12px;
  letter-spacing: -0.5px;
  line-height: 16px;
  margin-left: 40px;
}

.apply-discounts-optional-values {
  margin-left: 40px;
}

.apply-discounts-optional-values ion-item {
  --padding-start: 0px;
  --ion-background-color: #ffffff;
  --ion-item-background: #ffffff;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  display: flex;
}

.apply-discounts-optional-values ion-input {
  border: solid 1px #ededed;
  border-radius: 4px;
  height: 35px;
  max-width: 107px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: initial !important;
  display: flex;
  flex-direction: row;
  --padding-start: 5px !important;
}

.subSetting ion-input {
  border: solid 1px #ededed;
  border-radius: 4px;
  height: 35px;
  max-width: 107px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: initial !important;
  display: flex;
  flex-direction: row;
  --padding-start: 5px !important;
}

.input-dollar-amount:before {
  content: "$";
  font-size: 20px;
  opacity: 0.25;
  color: #000000;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.input-percent:after {
  content: "%";
  font-size: 20px;
  opacity: 0.25;
  color: #000000;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.control-invalid {
  border: 1px solid #ff0000 !important;
}

.clutch-setting ion-item {
  --padding-start: 0px;
  --ion-background-color: #ffffff;
  --ion-item-background: #ffffff;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  display: flex;
}

.clutch-setting ion-label {
  width: 75px;
}

.clutch-setting ion-input {
  border: 1px solid #000000;
  border-radius: 4px;
  height: 35px;
  max-width: 200px;
  margin-left: 10px;
  margin-right: 10px;
}
