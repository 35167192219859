ion-content.user-import-grid {
    --ion-background-color: #ffffff;
    border-top: solid 5px #EDEDED;
    border-bottom: solid 1px #EDEDED;
}

.user-lineItem {
    --background: #ffffff;
    --border-color: #EDEDED;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 20px;
    color: #7F7F7F;
}

.user-header-table {
    margin-left: 5px;
}

.user-header {
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 16px;
    text-align: center;
    padding: 10px;
}

.user-import-grid {
    height: 284px;
}

.user-grid-padding {
    padding-top: 10px;
}

.import-user-heading {
    width: 624px;
    margin-left: calc(50% - 312px);
    margin-top: 50px;
}

.import-user-heading h2 {
    font-size: 24px;
    letter-spacing: -0.5px;
    line-height: 32px;
}

.import-user-heading div {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 24px;
}

.no-click {
    pointer-events: none !important;
}

.chip-no-click {
    pointer-events: none;
    --background: #616AE8;
    height: 24px;
    width: 32px;
    margin-right: 15px;
}

.chip-no-click ion-label {
    color: #ffffff;
}

.failure-popover {
    min-width: 520px;
}

.failure-popover div {
    margin: 15px;
}

ion-list.failures-list {
    background: #ffffff;
}

.import-users-card .first-name {
    margin-left: 16px;
    margin-right: 5px;
    width: 130px;
}

.import-users-card .last-name {
    width: 130px;
    margin-left: 5px;
    margin-right: 5px;
}

.import-users-card .email {
    width: 265px;
    margin-left: 5px;
    margin-right: 5px;
}

.import-users-card .role {
    width: 160px;
    margin-left: 5px;
    margin-right: 5px;
}

.import-users-card .locations {
    width: 175px;
}

.import-users-button {
    margin: 24px;
}

.selected-employees {
    font-size: 12px;
    letter-spacing: -0.5px;
    line-height: 16px;
    margin-top: 30px;
    margin-left: 15px;
}

.user-close-button {
    height: 30px;
    width: 30px;
    margin-right: 28.5px;
    margin-top: 28.5px;
    fill: #000000;
    cursor: pointer;
}

.checkboxHolder {
    display: inline;
}

.checkboxHolder input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
}

.checkbox {
    display: block;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkmarkDisabled {
    background-color: white;
}

.checkmark {
    float: left;
    height: 17px;
    width: 17px;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 2px;
    background: #EDEDED;
    cursor: pointer;
}

.checkmarkEnabled {
    background-color: #eee;
}

.checkbox:hover input~.checkmarkDisabled {
    background-color: white;
}

.checkbox:hover input~.checkmarkEnabled {
    background-color: #ccc;
}

.checkbox input:checked~.checkmark {
    background: white;
    border: 5px solid #616AE8;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked~.checkmark {
    background: white;
    border: 5px solid #616AE8;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.pagination-controls IonButton {
    padding: 0;
}

.pagination-controls IonText {
    text-align: center;
}

.user-search-input {
    height: 32px;
    width: 225px;
    min-height: 32px !important;
    min-width: 225px !important;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    color: #000000;
    --padding-start: 8px;
    --padding-end: 8px;
    margin-left: 8px;
    margin-right: 0px;
}

.user-search-container {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}