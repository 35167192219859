.loginPage {
	min-width: 320px;
	min-height: 480px;
	height: 100%;
	overflow-y: scroll;
	background: #592A60;
}

.loginPage .section1 {
	position: relative;
	width: 100%;
	height: 92%;
	overflow: hidden;
	background-size: cover;
	background-position: center;
}

.loginPage .section1 .content {
	position: absolute;
	left: 48%;
	top: 33%;
	width: 40%;
	min-height: 37%;
	padding: 1.5%;
	color: white;
	transition: all 0.6s linear;
	overflow: hidden;
}

.loginPage .section1 .content.out {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 445px;
	max-width: 900px;
	transform: translateX(-50%) translateY(-50%);
	width: 45%;
	height: 75%;
	padding: 0;
	min-height: 491px;
}

.loginPage .section1 .content .loginForm {
	position: absolute;
	width: 100%;
	top: 0;
	height: 0;
	transition: opacity 0.3s linear, height 0.15s linear;
	overflow: hidden;
}

.loginPage .section1 .content .loginForm.out {
	opacity: 0;
	visibility: hidden;
}

.loginPage .section1 .content .loginForm.in {
	transition: opacity 0.3s linear;
	opacity: 1;
	height: 100%;
}

.loginPage .section1 .content .loginForm .row1 {
	height: 40%;
	position: relative;
	width: 80%;
	margin-left: 10%;
}

.loginPage .section1 .content .loginForm .row1 .portalLogo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60%;
	height: 60%;
}

.loginPage .section1 .content .loginForm .row2 {
	width: 65%;
	margin-left: 18.5%;
	margin-top: 60px;
	height: 150px;
}

.loginPage .section1 .content .loginForm .row2 ion-input {
	width: 95%;
}

.loginPage .section1 .content .loginForm .row2 input {
	outline: 0;
	color: black;
	border-width: 0 0 1px;
	border-color: white;
	background: white;
	width: 90%;
	margin-left: 5%;
	padding: 20px;
	font-size: 18px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.loginPage .section1 .content .loginForm .row2 input:-webkit-autofill,
.loginPage .section1 .content .loginForm .row2 input:-webkit-autofill:hover,
.loginPage .section1 .content .loginForm .row2 input:-webkit-autofill:focus
.loginPage .section1 .content .loginForm .row2 textarea:-webkit-autofill,
.loginPage .section1 .content .loginForm .row2 textarea:-webkit-autofill:hover
.loginPage .section1 .content .loginForm .row2 textarea:-webkit-autofill:focus,
.loginPage .section1 .content .loginForm .row2 select:-webkit-autofill,
.loginPage .section1 .content .loginForm .row2 select:-webkit-autofill:hover,
.loginPage .section1 .content .loginForm .row2 select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
	background-color: white !important;
}

.loginPage .section1 .content .loginForm .row2 passwordInput {
	margin-top: 7%;
}

.loginPage .section1 .content .loginForm .row3 {
	height: 20%;
	width: 80%;
	margin-left: 10%;
	width: 65%;
	margin-left: 18.5%;
	height: 55px;
	margin-top: 13px;
}

.loginPage .section1 .content .loginForm .row3 .loginButton {
	margin: auto;
	display: block;
	font-size: 14px;
	border-radius: 10px;
	padding: 20px;
	width: 90%;
	margin-left: 5%;
	height: 100%;
}

.loginPage .section1 .content .loginForm .row3 .loginButton:disabled {
	background: rgba(255, 255, 255, 0.25);
	color: rgba(255, 255, 255, 0.5);
	cursor: unset;
}

.loginPage .section1 .content .loginForm .row3 .forgotPasswordLink {
	font-size: 13px;
	text-decoration: underline;
	cursor: pointer;
	margin: auto;
	display: block;
	width: 50%;
	text-align: center;
	margin-top: 3%;
}

.loginPage .section1 .content .loginForm .row3 .forgotPasswordLink:hover {
	color: #b0b0b0;
}


#modalBody .forgotPasswordText {
	width: 80%;
	margin: auto;
	margin-bottom: 40px;
}

@keyframes dash {
	0% {
		stroke-dashoffset: 745.74853515625;
	}

	100% {
		stroke-dashoffset: 0;
	}
}

.setPasswordHolder {
	background: #592A60;
	background-size: cover;
	height: 100%;
	width: 100%;
}
