.user-import-grid ion-checkbox {
    display: flex;
    --background: #EDEDED;
    --border-color: #EDEDED;
    --background-checked: #616AE8;
    --border-color-checked: #616AE8;
    height: 16px;
    width: 16px;
}

.user-import-grid .email input {
    box-sizing: border-box;
    height: 36px;
    width: 233px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    color: #000000;
    padding: 5px;
}

.user-import-grid .email-invalid {
    border: 1px solid #ff0000 !important;
}

.user-import-grid .user-lineItem td {
    height: 56px;
    padding: 5px;
    border-bottom: solid 1px #EDEDED;
}

.user-control-invalid {	
    border: 1px solid #ff0000 !important;	
}

.user-import-grid .users-dropdown {
    width: 134px;
    height: 24px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.5px;
    border-radius: 4px;
    color: #616AE8;
    --box-shadow: none;
    background: hsla(236, 75%, 65%, 0.07);
    padding: 4px;
    text-align: center;
    cursor: pointer;
}

.user-import-grid .users-dropdown ion-icon {
    float: right;
}

.user-import-grid .icon {
    fill: #616AE8;
    height: 16px;
    width: 16px;
    opacity: 1.0;
}