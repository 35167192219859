.no-labels-content {
  width: 100%;
  padding: 25px;
  text-align: center;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
}

.labels-content-holder {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  max-height: 100%;
  overflow: hidden;
}

.labels-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;
}

.labels-content-header-holder {
  background-color: #f4f4f6;
  width: 80%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.labels-content-header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
}

.labels-content-button-holder {
  width: calc(100% / 5);
  display: flex;
}

@media only screen and (max-width: 1300px) {
  .labels-content-header {
    flex-direction: column;
  }

  .labels-content-button-holder {
    margin-bottom: 10px;
    width: initial;
  }
}

.labels-content-button {
  height: 32px;
  border-radius: 4px;
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 20px;
  padding: 6px 16px;
}

.labels-delete-button {
  margin-right: 8px;
  background-color: var(--rics-tertiary-color);
}

.labels-delete-button:hover {
  background-color: var(--rics-tertiary-color-shade);
}

.labels-content-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.labels-content-header-dropdowns {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: end;
}

.labels-content-header-dropdown {
  margin-left: 25px;
}

.dropdown-with-tooltip {
  display: flex;
}

.dropdown-with-tooltip .labels-content-header-dropdown {
  margin-right: 10px;
}

.stock-labels-grid-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  background: white;
  font-size: 12px;
  margin-bottom: 5px;
}

.stock-labels-grid-select {
 margin: 0 25px 0 0;
}

.stock-labels-grid-delete,
.stock-labels-grid-delete:focus {
  background: transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-labels-grid-delete:hover {
  background: transparent;
}

.stock-labels-grid-delete img {
  filter: invert(97%) sepia(0%) saturate(23%) hue-rotate(153deg) brightness(84%) contrast(97%);
  height: 20px;
  width: 20px;
}

.stock-labels-grid-delete img:hover {
  opacity: 0.8;
}

.stock-labels-grid-delete .delete-all {
  filter: invert(43%) sepia(14%) saturate(5566%) hue-rotate(215deg) brightness(94%) contrast(93%);
}

.stock-labels-header-value {
  font-weight: bold;
  text-decoration: underline;
}

.stock-labels-header-value:hover {
  cursor: pointer;
}

.stock-labels-grid-body {
  width: 80%;
  font-size: 14px;
  margin-bottom: 50px;
}

.stock-labels-grid-values {
  display: flex;
  flex: 1
}

.stock-labels-grid-values .small {
  font-size: inherit;
  width: calc(100% / 12);
}

.stock-labels-grid-values .medium {
  font-size: inherit;
  width: calc(100% / 10);
}

.stock-labels-grid-values .large {
  font-size: inherit;
  width: calc(100% / 8);
}

.stock-labels-grid-values .xlarge {
  font-size: inherit;
  width: calc(100% / 6);
}

.stock-labels-grid-values .center {
  text-align: center;
}

.stock-labels-grid-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.stock-labels-grid-row {
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  margin-top: 2px;
  padding: 5px 10px;
}

.stock-labels-grid-row .stock-labels-grid-quantity input[type=number] {
  width: 3rem;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.stock-labels-grid-row .stock-labels-grid-quantity input[type=number]::-webkit-outer-spin-button,
.stock-labels-grid-row .stock-labels-grid-quantity input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.stock-labels-grid-row .stock-labels-grid-quantity input[type=number] {
  -moz-appearance: textfield;
}

.stock-labels-grid-loading {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.stock-labels-grid-loading img {
  height: 30px;
  width: 30px;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  filter: invert(97%) sepia(0%) saturate(23%) hue-rotate(153deg) brightness(84%) contrast(97%);
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(-360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(-360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(-360deg);}
}

.delete-all-modal-contents {
  display: flex;
  flex-direction: column;
  width: 30rem;
}

.delete-all-modal-close-button {
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.delete-all-modal-close-button,
.delete-all-modal-close-button:hover,
.delete-all-modal-close-button:focus {
  background: transparent;
}

.delete-all-modal-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px 0 10px;
}

.delete-all-modal-text {
  margin-bottom: 10px;
}

.delete-all-modal-text-loud {
  font-size: 20px;
  font-weight: bold;
}

.delete-all-modal-buttons {
  display: flex;
  padding: 10px;
  justify-content: center;
}

.delete-all-modal-button {
  margin-left: 10px;
  width: calc(100% / 5);
  border-radius: 4px;
  padding: 3px;
}

.missing-printers-tooltip {
  width: 300px;
}

.missing-upc-modal-contents {
  display: flex;
  flex-direction: column;
  width: 60rem;
}

.missing-upc-modal-close-button {
  height: 20px;
  width: 20px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
}

.missing-upc-modal-close-button,
.missing-upc-modal-close-button:hover,
.missing-upc-modal-close-button:focus {
  background: transparent;
}

.missing-upc-modal-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 10px 0 10px;
}

.missing-upc-modal-text {
  margin-bottom: 10px;
}

.missing-upc-modal-text-loud {
  font-size: 20px;
  font-weight: bold;
}

.missing-upc-modal-buttons {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.missing-upc-modal-button {
  margin-left: 30px;
  width: calc(100% / 5);
  border-radius: 4px;
  padding: 3px;
}
