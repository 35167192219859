.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog {
    max-width: 1000px;
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-header {
    padding: 1rem;
    border-bottom: 1px solid #F3F3F3;
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-header .ecommerce-account-locations-modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-header .ecommerce-account-locations-modal-title .close-button {
    border: none;
    filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(338deg) brightness(106%) contrast(109%);
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-header .ecommerce-account-locations-modal-title .close-button:hover,
.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-header .ecommerce-account-locations-modal-title .close-button:focus {
    box-shadow: none;
}

.ecommerce-account-locations-modal-body .locations-table {
    margin-top: 30px;
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-footer .ok-button {
    background-color: #616ae8;
    font-size: 13px;
    letter-spacing: -0.5px;
    line-height: 20px;
    box-shadow: none;
    border: none;
    padding: 0 1rem;
    height: 33px;
    width: 120px;
}

.ecommerce-account-locations-modal .ecommerce-account-locations-modal-dialog .ecommerce-account-locations-modal-footer .ok-button:hover {
    opacity: 0.9;
}
