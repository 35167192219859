.edit-special-event-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.edit-tax-event-header {
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
}

.edit-tax-event-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
}

.edit-tax-event-sub-header {
    font-size: 14px;
    font-weight: bold;
    margin-top: 40px;
    margin-left: 30px;
}

.edit-tax-event-description {
    font-size: 14px;
    color: #989898;
    margin-top: 10px;
    margin-left: 30px;
}

.edit-tax-event-grid-container {
    border-bottom: 1px solid #eee;
    padding: 15px 20px 15px 20px;
}

.edit-tax-event-field-description {
    font-size: 12px;
    color: #989898;
    margin-bottom: 10px;
}

.edit-tax-event-taxes {
    display: flex;
    font-size: 13px;
    width: 100%;
    margin-top: 10px;
}

.edit-tax-event-taxes-item {
    margin-left: 0px;
    margin-right: 10px;
}

.edit-tax-event-name {
    border-radius: 3px;
    border: 1px solid #efefef;
    width: 100%;
    font-size: 14px;
    --padding-start: 10px;
}

.edit-tax-event-tax-free-button {
    width: 100%;
    font-size: 14px;
}

.edit-tax-event-start-date {
    font-size: 14px;
    width: 100%;
    height: 38px;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 3px;
}

.edit-tax-event-end-date {
    font-size: 14px;
    width: 100%;
    height: 38px;
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 3px;
}

.edit-tax-event-start-date-icon {
    color: #989898;
    height: 16px;
    width: 16px;
    position: absolute;
    right: 15px;
    top: 35px;
}

.edit-tax-event-end-date-icon {
    color: #989898;
    height: 16px;
    width: 16px;
    position: absolute;
    right: 15px;
    top: 35px;
}

.edit-tax-event-button-container {
    height: 100px;
    padding: 30px;
}

.edit-tax-event-button-container ion-button {
    margin: 0;
}

.edit-special-event-modal-close {
    height: 25px;
    width: 25px;
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
}

.tax-event-container {
    border: 1px solid #eee;
    border-radius: 3px;
    margin: 10px;
    padding: 10px;
    margin-left: 0px;
    font-size: 12px;
}

.tax-event-name {
    font-size: 14px;
}

.tax-event-header {
    margin-bottom: 20px;
}

.tax-event-date {
    font-size: 14px;
    color: #989898;
    margin-right: 20px;
}

.tax-event-menu-icon {
    cursor: pointer;
    height: 15px;
    width: 15px;
}

.popup-edit-button {
    height: 20px;
    color: black;
    text-align: left;
}

.popup-edit-button ion-icon {
    color: #989898;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.popup-duplicate-button {
    height: 20px;
    color: black;
    text-align: left;
}

.popup-duplicate-button ion-icon {
    color: #989898;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.popup-delete-button {
    height: 20px;
    color: #FF0000;
    text-align: left;
}

.popup-delete-button ion-icon {
    color: #FF0000;
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.popup-button-contents {
    text-align: left;
    width: 100%;
}

.edit-tax-event-cancel-button {
    --background: #EFEFEF;
    color: #000000;
}

.delete-special-tax-event-modal {
    --height: initial;
    --width: 35rem;
    --border-radius: 8px;
}

.delete-special-tax-event-modal .modal-wrapper {
    border-radius: 8px;
    width: 520px;
    height: 150px;
}

.special-event-modal-no-button {
    --background: #EF3030;
}

.special-event-modal-delete-text {
    text-align: center;
    margin-top: 40px;
}

.special-event-modal-delete-buttons {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
}

.special-event-modal-delete-buttons ion-button {
    width: 80px;
    margin: 5px;
}