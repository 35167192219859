.location-popover {
	--background: #ffffff;
}

.location-popover  ion-list {
	background-color: #ffffff;
}

.location-popover  ion-item {
	--background: #ffffff;
}

.location-popover ion-text {
	margin-left: 10px;
	font-size: 14px;
	font-weight: bold;
	letter-spacing: -0.5px;
	line-height: 20px;
}
